import React, { useContext, useEffect, useState } from "react";
import Confetti from "react-confetti";
import successProfileIcon from "../../assets/images/successProfileIcon.png";
import failureProfileIcon from "../../assets/images/failureProfileIcon.png";
import trophyGrey from "../../assets/svgs/trophyGrey.svg";
import LeaveQuizNavBar from "./LeaveQuizNavBar";
import CustomButton from "../../reusable/CustomButton";
import { staticQuizContext } from "../../context/context";
import { STATIC_QUIZ_MINIMUM_PERCENTAGE_TO_PASS } from "../../constants/EnvConstants";
import { useNavigate } from "react-router-dom";
import { LeaderBoardRoute } from "../../constants/RouteConstants";
const ResultScreen = ({ goToFirstStep }) => {
  const navigate = useNavigate();
  const navigateToLeaderBoard = () => navigate(LeaderBoardRoute);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [showConfetti, setShowConfetti] = useState(true);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (showConfetti) {
      const confettiTimer = setTimeout(() => {
        setShowConfetti(false);
      }, 8000); // Stop confetti after 5 seconds
      return () => clearTimeout(confettiTimer);
    }
  }, [showConfetti]);
  const {
    noOfCorrectlyAnsweredQuestions,
    noOfInCorrectlyAnsweredQuestions,
    userRanking,
    resetStaticQuizState,
  } = useContext(staticQuizContext);
  const handlePlayAgainClick = () => {
    resetStaticQuizState();
    goToFirstStep();
  };
  const totalQuestions =
    noOfCorrectlyAnsweredQuestions + noOfInCorrectlyAnsweredQuestions;
  const percentage = (noOfCorrectlyAnsweredQuestions * 100) / totalQuestions;
  const isPassed = percentage >= STATIC_QUIZ_MINIMUM_PERCENTAGE_TO_PASS;
  return (
    <div className="flex flex-col items-center text-white min-h-svh bg-[#45377E] overflow-y-auto w-[100%] mobile:pb-0 pb-14">
      {isPassed && showConfetti && (
        <Confetti
          width={windowWidth}
          height={windowHeight}
          gravity={0.04} // Adjust this value to control the speed
        />
      )}
      <LeaveQuizNavBar title={"Back to Home"} />
      <div className="flex flex-col items-center mobile:mt-[10em] mt-[3em]">
        <img
          className={`${
            isPassed ? "w-[15em] h-[15em]" : "w-[12em] h-[12em]"
          } object-contain mb-8`}
          src={isPassed ? successProfileIcon : failureProfileIcon}
          alt="Profile"
        />
        <h1 className="laptop:text-[4.2em] tablet:text-[4.1em] mobile:text-[4em] text-[3.9em] font-[800] mb-[0.2em]">
          {isPassed ? "You did it!" : "Let's try again!"}
        </h1>
        {userRanking === 1 && !isPassed ? (
          <></>
        ) : (
          <p className="laptop:text-[2em] tablet:text-[1.8em] mobile:text-[1.6em] text-[1.5em] mb-[2.5em]">
            Your ranking is{" "}
            <span className="text-[#FF43BB] font-[700]">{userRanking}</span> on
            leaderboard!
          </p>
        )}
        <div className="flex space-x-6 mb-9">
          <div className="flex flex-col items-center bg-[#5E4D96CC] rounded-[13px] mobile:px-[6.7em] mobile:py-[1.4em] px-[5.4em] py-[1em]">
            <span className="laptop:text-[4em] tablet:text-[3.8em] mobile:text-[3.5em] text-[3.3em] font-[800]">
              {noOfCorrectlyAnsweredQuestions}
            </span>
            <span className="laptop:text-[2em] tablet:text-[1.7em] mobile:text-[1.5em] text-[1.3em] text-[#FFFFFF80]">
              Correct
            </span>
          </div>
          <div className="flex flex-col items-center bg-[#5E4D96CC] rounded-[13px] mobile:px-[6.7em] mobile:py-[1.4em] px-[5.4em] py-[1em]">
            <span className="laptop:text-[4em] tablet:text-[3.8em] mobile:text-[3.5em] text-[3.3em] font-[800]">
              {noOfInCorrectlyAnsweredQuestions}
            </span>
            <span className="laptop:text-[2em] tablet:text-[1.7em] mobile:text-[1.5em] text-[1.3em] text-[#FFFFFF80]">
              Wrong
            </span>
          </div>
        </div>
        <p className="laptop:text-[2em] tablet:text-[1.7em] mobile:text-[1.5em] text-[1.3em] text-[#FFFFFF99]">
          Total Questions: <span className="font-[700]">{totalQuestions}</span>
        </p>
        <div className="mobile:mt-12 mt-48 flex flex-col items-center space-y-10">
          <CustomButton
            text="Play Again"
            onClick={handlePlayAgainClick}
            className={
              "tablet:!text-[2em] mobile:!text-[1.8em] !text-[1.5em] font-[600] mobile:!py-[1em] !w-[19em] px-[1.5em] !py-[1.2em] uppercase"
            }
          />
          <div className="flex items-center space-x-2 opacity-60">
            <img
              className="object-contain mobile:w-auto w-[3em]"
              src={trophyGrey}
              alt=""
            />
            <button
              onClick={navigateToLeaderBoard}
              className=" laptop:text-[2em] tablet:text-[1.7em] mobile:text-[1.5em] text-[1.5em] text-[#FFFFFFB3] hover:underline "
            >
              Go To Leaderboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResultScreen;
