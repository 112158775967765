import React from "react";
import LoadingScreen from "./LoadingScreen";
import LeaveQuizNavBar from "./LeaveQuizNavBar";

const TryingToJoin = () => {
  return (
    <div className="h-svh overflow-hidden text-white bg-[#45377E]">
      <LeaveQuizNavBar />
      <LoadingScreen text={"Joining"} />
    </div>
  );
};

export default TryingToJoin;
