import React from "react";
import LoadingScreen from "./LoadingScreen";
import LeaveQuizNavBar from "./LeaveQuizNavBar";
import { useContext } from "react";
import { liveQuizContext } from "../../context/context";

const WaitingForPlayersToJoin = () => {
  const {
    liveQuizDifferenceBetweenScheduledAndStartTime,
    liveQuizQASessionStartsInTime,
  } = useContext(liveQuizContext);
  const percentage =
    (liveQuizQASessionStartsInTime * 100) /
    liveQuizDifferenceBetweenScheduledAndStartTime;
  return (
    <div className="h-svh overflow-hidden text-white bg-[#45377E]">
      <LeaveQuizNavBar
        timer={liveQuizQASessionStartsInTime}
        percentage={percentage}
      />
      <LoadingScreen text={"Waiting For Players To Join"} />
    </div>
  );
};

export default WaitingForPlayersToJoin;
