import { Link } from "react-router-dom";
import { TermsAndConditionsPageRoute } from "../constants/RouteConstants";

const FAQData = {
  title: "QuizWiz FAQs",
  faqs: [
    {
      question: "What is QuizWiz?",
      answer: [
        "QuizWiz is an interactive web app that offers daily quizzes and live battle quizzes where users can compete and win various incentives such as mobile data and other prizes.",
      ],
    },
    {
      question: "How do I sign up for QuizWiz?",
      answer: [
        "You can sign up by visiting our webapp and creating an account with your phone number. Follow the on-screen instructions to complete the registration process.",
      ],
    },
    {
      question: "What types of quizzes are available on QuizWiz?",
      answer: [
        "QuizWiz offers daily quizzes, which you can take at any time, and live battle quizzes, where you compete against other users in real-time for rewards.",
        "There are many categories you can play quizzes in.",
      ],
    },
    {
      question: "How do I participate in a live battle quiz?",
      answer: [
        "To join a live battle quiz, log in to your QuizWiz account at the scheduled time, navigate to the Battle Quiz section, and join the quiz room. Follow the instructions to start competing.",
      ],
    },
    {
      question: "What incentives can I win on QuizWiz?",
      answer: [
        "Participants can win various incentives, including mobile data, mobile balance, and other exciting prizes, depending on their performance in the quizzes.",
      ],
    },
    {
      question: "Is there a fee to use QuizWiz?",
      answer: [
        "QuizWiz works on a subscription-based model. If you subscribe to QuizWiz service, you’d be charged 7 Rs per week.",
      ],
    },
    {
      question: "How is my privacy protected on QuizWiz?",
      answer: [
        <>
          We take your privacy seriously. Please refer to our{" "}
          <Link
            to={TermsAndConditionsPageRoute}
            className="text-blue-600 underline"
          >
            Privacy Policy
          </Link>{" "}
          for detailed information on how we collect, use, and protect your
          personal information.
        </>,
      ],
    },
    {
      question:
        "What happens if I encounter an issue or have a question about QuizWiz?",
      answer: [
        "If you have any questions or encounter issues, you can add the query in the Feedback section. We are here to help you with any concerns.",
      ],
    },
    {
      question: "How do I redeem my prize on QuizWiz?",
      answer: [
        "Once you win a reward, you will receive instructions on how to redeem it. Rewards such as mobile data will be credited to your account automatically.",
      ],
    },
  ],
  finalNotice:
    "These FAQs provide an overview of how QuizWiz works and what users can expect from the service. If you have more specific questions, please feel free to reach out to our support team.",
};

export default FAQData;
