import axios from "axios";
import { useContext, useState } from "react";
import { appContext } from "../../context/context";
import { BASE_URL, ENDPOINT_REQUEST_OTP } from "../../constants/APIConstant";
import useHandleError from "../useHandleError";
import { useAlert } from "react-alert";

const useRequestOtp = () => {
  const appState = useContext(appContext);
  const handleError = useHandleError();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const requestOtp = async (msisdn, callback) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}${ENDPOINT_REQUEST_OTP}`,
        { msisdn },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      appState.saveMsisdn(msisdn);
      const { message } = response.data;
      alert.success(message.replace("92", "0"));
      callback();
    } catch (error) {
      const TOO_MANY_REQUESTS_STATUS_CODE = 429;
      if (error?.response?.status === TOO_MANY_REQUESTS_STATUS_CODE) {
        appState.saveMsisdn(msisdn);
        callback();
      }
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  return { requestOtp, loading };
};

export default useRequestOtp;
