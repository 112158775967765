import React, { useEffect, useState } from "react";
import Cylinder from "./Cylinder";
import Tooltip from "./Tooltip";
import FirstprofileIcon from "../../assets/images/icons/leaderboard/FirstprofileIcon.png";
import secondProfileIcon from "../../assets/images/icons/leaderboard/secondProfileIcon.png";
import seeMoreIcon from "../../assets/svgs/seeMoreIcon.svg";
import profileIcon from "../../assets/svgs/userMobile.svg";
import ToggleButton from "./ToggleButton";
import LeaveQuizNavBar from "../staticQuiz/LeaveQuizNavBar";
import useGetDailyStaticQuizLeaderBoard from "../../hooks/userScore/useGetDailyStaticQuizLeaderBoard";
import useGetWeekyStaticQuizLeaderBoard from "../../hooks/userScore/useGetWeeklyStaticQuizLeaderBoard";
import { logEvent } from "firebase/analytics";
import analytics from "../../firebase";

const LeaderBoard = () => {
  const getDailyLeaderBoard = useGetDailyStaticQuizLeaderBoard();
  const getWeeklyLeaderBoard = useGetWeekyStaticQuizLeaderBoard();
  const [activeToggle, setActiveToggle] = useState("Daily");
  const [dailyLeaderBoardIsLoaded, setDailyLeaderBoardIsLoaded] =
    useState(false);
  const [dailyLeaderBoard, setDailyLeaderBoard] = useState([]);
  const [weeklyLeaderBoardIsLoaded, setWeeklyLeaderBoardIsLoaded] =
    useState(false);
  const [weeklyLeaderBoard, setWeeklyLeaderBoard] = useState([]);

  useEffect(() => {
    if (activeToggle === "Daily") {
      logEvent(analytics, "Daily_Quiz_Leaderboard_Opened", {
        date: new Date().toISOString(),
      });
    } else {
      logEvent(analytics, "Weekly_Leaderboard_Opened", {
        date: new Date().toISOString(),
      });
    }
  }, [activeToggle]);  

  useEffect(() => {
    const getDailyLeaderBoardCallBack = (data) => {
      setDailyLeaderBoardIsLoaded(true);
      setDailyLeaderBoard(data);
    };
    getDailyLeaderBoard(getDailyLeaderBoardCallBack);

    const getWeeklyLeaderBoardCallBack = (data) => {
      setWeeklyLeaderBoardIsLoaded(true);
      setWeeklyLeaderBoard(data);
    };
    getWeeklyLeaderBoard(getWeeklyLeaderBoardCallBack);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSelectedLeaderBoardWeekly = activeToggle === "Weekly";

  const currentLeaderBoard = isSelectedLeaderBoardWeekly
    ? weeklyLeaderBoard
    : dailyLeaderBoard;
  const isLoaded = isSelectedLeaderBoardWeekly
    ? weeklyLeaderBoardIsLoaded
    : dailyLeaderBoardIsLoaded;
  const isLeaderBoardEmpty = currentLeaderBoard.length === 0;
  const hasFirstPoistion = currentLeaderBoard.length >= 1;
  const hasSecondPoistion = currentLeaderBoard.length >= 2;
  const hasThirdPoistion = currentLeaderBoard.length >= 3;
  const hasMoreThanThreePoistions = currentLeaderBoard.length > 3;

  return (
    <div className="bg-[#45377E] -">
      <div
        className={`flex flex-col justify-between  h-svh overflow-y-hidden text-white  ${
          hasMoreThanThreePoistions ? "" : "items-center"
        } `}
      >
        <LeaveQuizNavBar title={"Back to Home"} />
        <div className="tablet:mt-[2em] mt-[3em] ">
          <ToggleButton active={activeToggle} setActive={setActiveToggle} />
        </div>
        {isLoaded ? (
          isLeaderBoardEmpty ? (
            <div className="min-h-[33%] tablet:text-[2em] mobile:text-[1.8em] text-[1.5em] text-[#FFFFFF99] content-end select-none">
              No Leaders Found
            </div>
          ) : (
            <></>
          )
        ) : (
          <div className="min-h-[33%] tablet:text-[2em] mobile:text-[1.8em] text-[1.5em] text-[#FFFFFF99] content-end select-none">
            Loading...
          </div>
        )}
        <div
          className={` tablet:flex tablet:flex-row tablet:items-center tablet:justify-between laptop:gap-x-[19.8em] tablet:gap-[5vw] tablet:mx-12 mt-2 tablet:mt-0 ${
            !hasMoreThanThreePoistions
              ? "overflow-hidden"
              : "tablet:overflow-hidden overflow-x-hidden"
          }`}
        >
          <div
            className={` ${
              isLoaded && !isLeaderBoardEmpty
                ? hasMoreThanThreePoistions
                  ? ""
                  : "tablet:translate-y-10"
                : "translate-y-[150%]"
            } flex bg-slate-400- justify-center items-end gap-[1.8em] tablet:gap-[5em] mobile:gap-[5em] transition-all duration-1000 delay-500   w-full`}
          >
            {/* SECOND POSITION <--START--> */}
            {hasSecondPoistion ? (
              <LeaderBoardLeaderTile
                profileIcon={secondProfileIcon}
                profileImgClass={"w-[6.2em]"}
                toolTipText={currentLeaderBoard[1].userName}
                toolTipMaxWidth={""}
                toolTipTextColor={"text-[#45377E]"}
                toolTipBgColor={"bg-[#E6DCFF]"}
                toolTipBgTipColor={"border-t-[#E6DCFF]"}
                cylinderText={2}
                cylinderMinHeightClass={"min-h-[35vh] tablet:min-h-[40vh]"}
                TileclassName={"pl-[2em] tablet:pl-[0em]"}
              />
            ) : (
              <></>
            )}
            {/* SECOND POSITION <--END--> */}

            {/* FIRST POSITION <--START--> */}
            {hasFirstPoistion ? (
              <div className="relative">
                {" "}
                <LeaderBoardLeaderTile
                  profileIcon={FirstprofileIcon}
                  profileImgClass={"w-[9.8em]"}
                  toolTipText={currentLeaderBoard[0].userName}
                  toolTipMaxWidth={""}
                  toolTipTextColor={"text-[#FFFFFF]"}
                  toolTipBgColor={"bg-[#FF9479]"}
                  toolTipBgTipColor={"border-t-[#FF9479]"}
                  cylinderText={1}
                  cylinderMinHeightClass={"min-h-[40vh] tablet:min-h-[50vh]"}
                />
                {hasMoreThanThreePoistions && (
                  <img
                    src={seeMoreIcon}
                    className="tablet:hidden block opacity-60 animate-beat size-16 absolute bottom-24 left-[32%] transform -translate-x-1/2"
                    alt=""
                  />
                )}
              </div>
            ) : (
              <></>
            )}
            {/* FIRST POSITION <--END--> */}

            {/* THIRD POSITION <--START--> */}

            {hasThirdPoistion ? (
              <LeaderBoardLeaderTile
                profileIcon={secondProfileIcon}
                toolTipText={currentLeaderBoard[2].userName}
                toolTipMaxWidth={""}
                toolTipTextColor={"text-[#45377E]"}
                toolTipBgColor={"bg-[#E6DCFF]"}
                toolTipBgTipColor={"border-t-[#E6DCFF]"}
                cylinderText={3}
                cylinderMinHeightClass={"min-h-[30vh]"}
                profileImgClass={"w-[5em]"}
                TileclassName={"tablet:pr-[0em] pr-[2em]"}
              />
            ) : (
              <></>
            )}
            {/* THIRD POSITION <--END--> */}
          </div>
          {hasMoreThanThreePoistions && (
            <div className="flex relative justify-center w-full z-50 mb-4">
              <div className="tablet:static  w-[100%] tablet:h-max bg-white text-gray-500 laptop:text-nowrap rounded-[15px]  mobile:text-[1.8em] text-[1.5em]">
                {currentLeaderBoard.slice(3).map((entry, index) => (
                  <div
                    key={index}
                    className={`flex items-center justify-between ${
                      index % 2 === 0 ? "" : " bg-[#8789B026]"
                    }  px-[1em] py-[1em]`}
                  >
                    <div className="flex items-center space-x-4">
                      <img src={profileIcon} alt="" />
                      <div>{entry.userName}</div>
                    </div>
                    <div>{index + 4}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeaderBoard;

const LeaderBoardLeaderTile = ({
  profileIcon,
  profileImgClass,
  toolTipText,
  toolTipMaxWidth,
  toolTipTextColor,
  toolTipBgColor,
  toolTipBgTipColor,
  cylinderText,
  cylinderTextColor,
  cylinderMinHeightClass,
  TileclassName,
}) => {
  return (
    <div
      className={`flex flex-col items-center space-y-12 ${TileclassName || ""}`}
    >
      {" "}
      <div className="">
        <img
          className={`object-contain mobile:w-auto ${profileImgClass || ""}`}
          src={profileIcon}
          alt=""
        />
      </div>
      <Tooltip
        text={toolTipText}
        maxWidth={toolTipMaxWidth}
        textColor={toolTipTextColor}
        bgColor={toolTipBgColor}
        bgTipColor={toolTipBgTipColor}
      />
      <Cylinder
        text={cylinderText}
        textColor={cylinderTextColor}
        minHeightClass={cylinderMinHeightClass}
      />
    </div>
  );
};
