import { useContext } from "react";
import { appContext } from "../../context/context";
import useHandleError from "../useHandleError";
import axios from "axios";
import {
  BASE_URL,
  ENDPOINT_GET_RTM_CREDENTIALS,
} from "../../constants/APIConstant";
import { getCurrentTimestamp } from "../../functions/timeFunctions";

const useGetRtmCredentials = () => {
  const handleError = useHandleError();

  const { token } = useContext(appContext);
  const GetRtmCredentials = (callback) => {
    axios
      .request({
        method: "POST",
        url: BASE_URL + ENDPOINT_GET_RTM_CREDENTIALS,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify({
          joinedTimestamp: getCurrentTimestamp(),
        }),
      })
      .then((response) => {
        const data = response?.data;
        callback(data?.rtmToken);
      })
      .catch((error) => {
        handleError(error);
      });
  };
  return GetRtmCredentials;
};

export default useGetRtmCredentials;
