import { useContext } from "react";
import { appContext } from "../../context/context";
import axios from "axios";
import {
  BASE_URL,
  ENDPOINT_UPDATE_STATS_ON_JOIN,
} from "../../constants/APIConstant";
import { getCurrentTimestamp } from "../../functions/timeFunctions";

const useStatsOnJoin = () => {
  const { token } = useContext(appContext);
  const statsOnJoin = (callback, errorCallabck) => {
    const url = BASE_URL + ENDPOINT_UPDATE_STATS_ON_JOIN;
    axios
      .request({
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify({
          joinedTimestamp: getCurrentTimestamp(),
        }),
      })
      .then(() => {
        callback();
      })
      .catch(() => {
        errorCallabck();
      });
  };
  return statsOnJoin;
};

export default useStatsOnJoin;
