import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Dashboard/Header";
import { Outlet, useNavigate } from "react-router-dom";
import {
  appContext,
  deviceContext,
  staticQuizContext,
} from "../context/context";
import { LoginPageRoute } from "../constants/RouteConstants";

const Home = () => {
  const { isTabletView } = useContext(deviceContext);
  const [isOpen, setIsOpen] = useState(true);

  const { isLoggedIn } = useContext(appContext);
  const { isStaticQuizMode } = useContext(staticQuizContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(LoginPageRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [navigate]);

  const contentComponent = (
    <div className={`max-h-svh h-svh flex flex-col bg-[#45377E] w-[100%]`}>
      {isStaticQuizMode ? <></> : <Header />}
      <section className="flex-grow overflow-y-auto">
        <Outlet />
      </section>
    </div>
  );
  return isTabletView || isStaticQuizMode ? (
    <>{contentComponent}</>
  ) : (
    <div className={`flex h-svh bg-[#45377E] text-white w-[100%]`}>
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      {contentComponent}
    </div>
  );
};

export default Home;
