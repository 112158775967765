import React, { useState, useEffect, useContext } from "react";
import CustomButton from "../../reusable/CustomButton";
import { staticQuizContext } from "../../context/context";
import useGetStaticQuiz from "../../hooks/staticQuiz/useGetStaticQuiz";
import LeaveQuizNavBar from "./LeaveQuizNavBar";
import useTestStaticQuizAnswer from "../../hooks/staticQuiz/useTestStaticQuizAnswer";
// import LoadingComponent from "../LoadingComponent";
import {
  STATIC_QUIZ_TIME_PER_QUESTION_IN_SECONDS,
  STATIC_QUIZ_TIME_TO_DISPLAY_ANSWER_BEFORE_NEXT_QUESTION_IN_SECONDS,
} from "../../constants/EnvConstants";
import LoadingScreen from "../liveQuiz/LoadingScreen";

const QuestionScreen = ({ goToNextStep }) => {
  const { staticQuizQuestions, selectedQuestionNo, selectNextQuestion } =
    useContext(staticQuizContext);
  const { getStaticQuiz, loading } = useGetStaticQuiz();
  const testStaticQuizAnswer = useTestStaticQuizAnswer();
  const [selectedOption, setSelectedOption] = useState(0);
  const [isCorrect, setIsCorrect] = useState(false);
  const [timer, setTimer] = useState(STATIC_QUIZ_TIME_PER_QUESTION_IN_SECONDS);
  const [maxTime, setMaxTime] = useState(
    STATIC_QUIZ_TIME_PER_QUESTION_IN_SECONDS
  );
  const [showResult, setShowResult] = useState(false);
  const [questionStartTime, setQuestionStartTime] = useState(null);

  useEffect(() => {
    setQuestionStartTime(Date.now());
  }, [staticQuizQuestions]);

  useEffect(() => {
    if (timer > 0) {
      const timerId = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(timerId);
    } else if (timer === 0 && !showResult) {
      handleTimeEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  useEffect(() => {
    if (showResult) {
      setTimer(
        STATIC_QUIZ_TIME_TO_DISPLAY_ANSWER_BEFORE_NEXT_QUESTION_IN_SECONDS
      );
      setMaxTime(
        STATIC_QUIZ_TIME_TO_DISPLAY_ANSWER_BEFORE_NEXT_QUESTION_IN_SECONDS
      );
    }
  }, [showResult]);

  useEffect(() => {
    getStaticQuiz();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  if (loading) {
    return <LoadingScreen />;
  }

  if (staticQuizQuestions.length === 0) {
    return (
      <div className="h-full w-full flex justify-center items-center">
        <div className="flex flex-col items-center space-y-6 mb-[10%]">
          <h1 className="text-[23px] font-[600] text-[#8789B0]">
            No Questions Found...
          </h1>
          <h1 className="text-[23px] font-[600] text-[#8789B0]">
            Try Again Later
          </h1>
        </div>
      </div>
    );
  }

  const question = staticQuizQuestions[selectedQuestionNo];

  const handleTimeEnd = () => {
    const testAnswerCallback = (isTrue, isFinished) => {
      setIsCorrect(isTrue);
      const scheduledFunction = () => {
        if (isFinished) {
          goToNextStep();
        } else {
          goToNextQuestion();
        }
      };
      if (selectedOption !== 0) {
        setShowResult(true);
        setTimeout(
          scheduledFunction,
          STATIC_QUIZ_TIME_TO_DISPLAY_ANSWER_BEFORE_NEXT_QUESTION_IN_SECONDS *
            1000
        );
      } else {
        scheduledFunction();
      }
    };

    testStaticQuizAnswer(
      question.questionId,
      selectedOption,
      questionStartTime,
      Date.now(),
      testAnswerCallback
    );
  };

  const handleNextClick = () => {
    if (!showResult) {
      handleTimeEnd();
    } else {
      goToNextQuestion();
    }
  };

  const goToNextQuestion = () => {
    selectNextQuestion();
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimer(STATIC_QUIZ_TIME_PER_QUESTION_IN_SECONDS);
    setMaxTime(STATIC_QUIZ_TIME_PER_QUESTION_IN_SECONDS);
    setQuestionStartTime(Date.now());
    setSelectedOption(0);
    setIsCorrect(null);
    setShowResult(false);
  };

  return (
    <div className="text-white max-h-svh bg-[#45377E] w-[100%] ">
      <LeaveQuizNavBar timer={timer} percentage={(timer * 100) / maxTime} />
      <div className="max-h-[calc(100vh_-_81px)] overflow-y-auto flex flex-col items-center">
        <div className="w-full mobile:w-[65%] min-w-3xl px-[4.7em] mobile:py-[5em] mobile:mt-[10vh] mt-[3em] mobile:border-[2px] mobile:border-[#5E4D96] rounded-[18px]">
          <span className="laptop:text-[2.4em] tablet:text-[2.2] mobile:text-[2.1em] text-[2em] font-[800] mb-2">
            Question {selectedQuestionNo + 1}
          </span>
          <span className="laptop:text-[1.8em] tablet:text-[1.7em] mobile:text-[1.6em] text-[1.4em]">
            /{staticQuizQuestions.length}
          </span>
          <div className="laptop:text-[1.7em] tablet:text-[1.5em] mobile:text-[1.3em] text-[1.2em] text-[#867CAA] mb-[1.5em]">
            {question.category}
          </div>

          <p className="mb-6 laptop:text-[2.4em] tablet:text-[2.1em] mobile:text-[1.9em] text-[1.7em] font-[700] text-[#FFFFFFB3] select-none">
            {question.question}
          </p>
          <div className="grid mobile:grid-cols-2 gap-4 text-white laptop:text-[2em] tablet:text-[1.8em] mobile:text-[1.6em] text-[1.4em] mb-[1vh]">
            {question.options.map((option, i) => (
              <button
                key={i}
                className={`p-3 rounded-lg select-none ${
                  selectedOption === i + 1
                    ? showResult
                      ? isCorrect
                        ? "bg-[#83C3C4] font-[600]"
                        : "bg-[#FF7070] font-[600]"
                      : "border-[2px] border-[#FFFFF] text-[#FFFFFF] font-[600]"
                    : "border-[1px] border-[#5E4D96] text-[#A4A7D1]"
                }`}
                onClick={() => handleOptionClick(i + 1)}
                disabled={showResult}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
        {showResult ? (
          <></>
        ) : (
          <div className="mobile:mt-[9em]- mt-[3em] text-center">
            <CustomButton
              text="NEXT"
              onClick={handleNextClick}
              className="!rounded-[10px] tablet:!text-[2em] mobile:!text-[1.8em] !text-[1.5em] font-[600] mobile:!py-[0.6em] mobile:!w-[13em] !w-[17em] !py-[0.8em] uppercase"
              isDisabled={selectedOption === 0}
              disabledBgOpacity="opacity-50"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionScreen;
