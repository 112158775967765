import React, { useEffect, useState } from "react";
import { deviceContext as DeviceContext } from "./context";
import { checkMobileView, checkTabletView } from "../functions/DeviceFunctions";

const DeviceState = (props) => {
  const isMobileViewConst = checkMobileView();
  const [isMobileView, setIsMobileView] = useState(isMobileViewConst);
  const isTabletViewConst = checkTabletView();
  const [isTabletView, setIsTabletView] = useState(isTabletViewConst);
  const [deviceHeight, setDeviceHeight] = useState(window.innerHeight);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateViewConstants = () => {
      setIsMobileView(checkMobileView());
      setIsTabletView(checkTabletView());
      setDeviceHeight(window.innerHeight);
      setDeviceWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateViewConstants);
  }, []);

  return (
    <DeviceContext.Provider
      value={{ isMobileView, isTabletView, deviceHeight, deviceWidth }}
    >
      {props.children}
    </DeviceContext.Provider>
  );
};

export default DeviceState;
