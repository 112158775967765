import axios from "axios";
import { BASE_URL, ENDPOINT_ADD_FEEDBACK } from "../../constants/APIConstant";
import useHandleError from "../useHandleError";
import { useContext } from "react";
import { appContext } from "../../context/context";
import { useAlert } from "react-alert";

const useAddFeedback = () => {
  const handleError = useHandleError();
  const alert = useAlert();
  const { token } = useContext(appContext);
  const addFeedback = (noOfStars, feedbackDescription, callback) => {
    axios
      .request({
        method: "POST",
        url: BASE_URL + ENDPOINT_ADD_FEEDBACK,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify({
          noOfStars: noOfStars,
          feedbackDescription: feedbackDescription,
        }),
      })
      .then((response) => {
        const message = response?.data?.message;
        alert.success(message);
        callback();
      })
      .catch((error) => {
        handleError(error);
      });
  };
  return addFeedback;
};

export default useAddFeedback;
