import successIcon from "../../assets/svgs/alertIcons/success-sign.svg";
import warningIcon from "../../assets/svgs/alertIcons/warning-sign.svg";
import informationIcon from "../../assets/svgs/alertIcons/information-sign.svg";

const AlertTemplate = ({ options, message, close }) => {
  const infoStyle = {
    backgroundColor: "white",
    color: "black",
  };
  const successStyle = {
    backgroundColor: "white",
    color: "green",
  };
  const warningStyle = {
    backgroundColor: "white",
    color: "red",
  };
  const style =
    options.type === "success"
      ? successStyle
      : options.type === "error"
      ? warningStyle
      : infoStyle;
  const icon =
    options.type === "success"
      ? successIcon
      : options.type === "error"
      ? warningIcon
      : informationIcon;
  return (
    <div
      style={style}
      className="flex z-[10000] items-center justify-around gap-8 w-[25em] m-10 px-6 py-3 rounded-lg"
    >
      <Image src={icon} alt={"icon"} />
      <div className="w-[70%]">
        <p className="text-[1.6em]">{message}</p>
      </div>
    </div>
  );
};
const Image = ({ src, alt }) => {
  return (
    <div className={`cursor-pointer size-12`}>
      <img className="w-full h-full object-contain" src={src} alt={alt} />
    </div>
  );
};
export default AlertTemplate;
