import React from "react";
import { Link, useLocation } from "react-router-dom";
import dashboardLogo from "../../assets/svgs/sideBarIcons/dashboard-logo-unactive.svg";
import aboutLogo from "../../assets/svgs/sideBarIcons/about-logo-unactive.svg";
import helpLogo from "../../assets/svgs/sideBarIcons/help-logo-unactive.svg";
import supportLogo from "../../assets/svgs/sideBarIcons/support-logo-unactive.svg";
import termsLogo from "../../assets/svgs/sideBarIcons/terms-logo-unactive.svg";
import policiesLogo from "../../assets/svgs/sideBarIcons/policies-logo-unactive.svg";
import unsubscribeLogo from "../../assets/svgs/sideBarIcons/unsubscribe-logo-unactive.svg";
import { useContext } from "react";
import { appContext } from "../../context/context";
import { UNSUBSCRIBE_MESSAGE } from "../../constants/EnvConstants";
import {
  AboutUsRoute,
  DashboardRoute,
  HelpAndFAQRoute,
  PoliciesRoute,
  SupportAndFeedbackRoute,
  TermsAndConditionsRoute,
} from "../../constants/RouteConstants";

const SideBarLinksMenu = ({ isOpen, onClose }) => {
  const { showPopup, closePopup } = useContext(appContext);
  const location = useLocation();
  const menuItems = [
    {
      text: "Dashboard",
      logo: dashboardLogo,
      route: DashboardRoute,
      isLink: true,
    },
    { text: "About Us", logo: aboutLogo, route: AboutUsRoute, isLink: true },
    {
      text: "Help and FAQ",
      logo: helpLogo,
      route: HelpAndFAQRoute,
      isLink: true,
    },
    {
      text: "Support and Feedback",
      logo: supportLogo,
      route: SupportAndFeedbackRoute,
      isLink: true,
    },
    {
      text: "Terms & Conditions",
      logo: termsLogo,
      route: TermsAndConditionsRoute,
      isLink: true,
    },
    {
      text: "Policies",
      logo: policiesLogo,
      route: PoliciesRoute,
      isLink: true,
    },
    {
      text: "Unsubscribe",
      logo: unsubscribeLogo,
      onClick: (event) => {
        event.preventDefault();
        showPopup(
          <div className="relative bg-white  rounded-lg shadow-lg  transform transition-all   mx-10 text-center h-[40vh] px-10 py-12 text-[2em]">
            <div
              className="absolute top-3 right-3 size-8 rounded-full flex justify-center items-center cursor-pointer select-none"
              onClick={closePopup}
            >
              X
            </div>
            {UNSUBSCRIBE_MESSAGE}
          </div>
        );
      },
    },
  ];
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    onClose();
  };

  return (
    <div className="flex-grow  flex justify-center min-w-max mt-10">
      <nav>
        <ul>
          {menuItems.map((item, i) =>
            item.isLink ? (
              <Link key={i} to={item.route} onClick={handleLinkClick}>
                <li
                  key={item.text}
                  className="mb-2 cursor-pointer flex items-center space-x-[1.1em] "
                >
                  <img src={item.logo} alt={`${item.text} logo`} className="" />
                  <div
                    className={`transition-all duration-500 ease-in-out whitespace-nowrap ${
                      isOpen ? "block" : "hidden"
                    }`}
                  >
                    <p
                      to="s"
                      className={`text-[#fffCC] text-[1.6em] hover:bg-[#594991CC] p-2 block rounded ${
                        location.pathname === item.route ? "font-bold" : ""
                      }`}
                    >
                      {item.text}
                    </p>
                  </div>
                </li>
              </Link>
            ) : (
              <li
                key={item.text}
                className="mb-2 cursor-pointer flex items-center space-x-[1.1em] "
                onClick={item.onClick}
              >
                <img src={item.logo} alt={`${item.text} logo`} className="" />
                <div
                  className={`transition-all duration-500 ease-in-out whitespace-nowrap ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  <p
                    href="s"
                    className="text-[#fffCC] text-[1.6em] hover:bg-[#594991CC] p-2 block rounded"
                  >
                    {item.text}
                  </p>
                </div>
              </li>
            )
          )}
        </ul>
      </nav>
    </div>
  );
};

export default SideBarLinksMenu;
