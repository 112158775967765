import React from "react";
import FAQData from "../../data/FAQData";

const HelpAndFAQ = () => {
  return (
    <section className="rounded-[13px] tablet:p-[5.7em] mobile:p-[3.7em] p-[2.7em] bg-white text-justify">
      <h1 className="text-[#45377E] tablet:text-[2.7em] mobile:text-[2em] text-[2em] font-[700] mb-[6px]">
        {FAQData.title}
      </h1>
      <br />
      <ol className="text-[#45377ECC] list-decimal ml-6 tablet:text-[1.8em] mobile:text-[1.6em] text-[1.2em]">
        {FAQData.faqs.map((faq, index) => (
          <li key={index} className="mb-4">
            <p className="text-[#45377E] font-bold mb-2">{faq.question}</p>
            <ul className="list-disc text-[#45377ECC] ml-8">
              {faq.answer.map((bullet, idx) => (
                <li key={idx} className="text-[#45377ECC]">
                  {bullet}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ol>
      <p className="text-[#45377ECC] tablet:text-[1.8em] mobile:text-[1.6em] text-[1.2em] mt-4 ml-6">
        {FAQData.finalNotice}
      </p>
    </section>
  );
};

export default HelpAndFAQ;
