import React, { useEffect } from "react";
import SupportAndFeedback from "../components/SupportAndFeedback/SupportAndFeedback";
import { logScreenView } from "../firebase";
function SupportFeedback() {
  useEffect(() => {
    logScreenView("SUpport & Feedback");
  }, []);
  return (
    <>
      <section className="tablet:p-[4em] mobile:p-[2em] p-[1em] ">
        <SupportAndFeedback />
      </section>
    </>
  );
}

export default SupportFeedback;
