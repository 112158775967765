// src/App.js
import React from "react";
import "./App.css";

import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Auth from "./pages/Auth";

import {
  DashboardRoute,
  AboutUsRoute,
  BaseRoute,
  HelpAndFAQRoute,
  SupportAndFeedbackRoute,
  TermsAndConditionsRoute,
  PoliciesRoute,
  NoMatchRoute,
  TermsAndConditionsPageRoute,
  PoliciesPageRoute,
  DailyQuizRoute,
  LeaderBoardRoute,
  LiveQuizRoute,
  LoginPageRoute,
  EmptyPageRoute,
} from "./constants/RouteConstants";
import AuthRoute from "./components/AuthRoute";
import About from "./pages/About";
import HelpFAQ from "./pages/HelpFAQ";
import SupportFeedback from "./pages/SupportFeedback";
import TermsConditions from "./pages/TermsConditions";
import TermsConditionsPage from "./pages/TermsConditions";
import LeaderBoardPage from "./pages/LeaderBoard";
import OurPolicies from "./pages/OurPolicies";
import OurPoliciesPage from "./pages/OurPolicies";
import Home from "./pages/Home";
import StaticQuiz from "./pages/StaticQuiz";
import LiveQuiz from "./pages/LiveQuiz";
import { useContext } from "react";
import { liveQuizContext } from "./context/context";
import useScrollToTop from "./hooks/useScrollToTop";
import EmptyPage from "./pages/EmptyPage";
function App() {
  const { isLiveQuizLive } = useContext(liveQuizContext);
  const pageRoutes = [
    {
      path: LoginPageRoute,
      element: <Auth />,
      isProtected: false,
      isAvailable: true,
    },
    {
      path: TermsAndConditionsPageRoute,
      element: <TermsConditionsPage />,
      isProtected: false,
      isAvailable: true,
    },
    {
      path: PoliciesPageRoute,
      element: <OurPoliciesPage />,
      isProtected: false,
      isAvailable: true,
    },
    {
      path: LeaderBoardRoute,
      element: <LeaderBoardPage />,
      isProtected: true,
      isAvailable: true,
    },
    {
      path: LiveQuizRoute,
      element: <LiveQuiz />,
      isProtected: true,
      isAvailable: isLiveQuizLive,
    },
    {
      path: EmptyPageRoute,
      element: <EmptyPage />,
      isProtected: false,
      isAvailable: true,
    },
  ];
  const dashboardOutletRoutes = [
    { path: DashboardRoute, element: <Dashboard /> },
    { path: AboutUsRoute, element: <About /> },
    { path: HelpAndFAQRoute, element: <HelpFAQ /> },
    { path: SupportAndFeedbackRoute, element: <SupportFeedback /> },
    { path: TermsAndConditionsRoute, element: <TermsConditions /> },
    { path: PoliciesRoute, element: <OurPolicies /> },
    { path: DailyQuizRoute, element: <StaticQuiz /> },

    // Add more routes as needed
  ];
  useScrollToTop();
  return (
    <div className="h-svh-">
      <Routes>
        <Route element={<AuthRoute />}>
          <Route path={BaseRoute} element={<Home />}>
            {dashboardOutletRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
            <Route
              path={NoMatchRoute}
              element={<Navigate to={DashboardRoute} replace />}
            />
          </Route>
        </Route>
        {pageRoutes.map(
          (route) =>
            route.isAvailable &&
            (route.isProtected ? (
              <Route key={route.path} element={<AuthRoute />}>
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              </Route>
            ) : (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))
        )}
        <Route
          path={NoMatchRoute}
          element={<Navigate to={DashboardRoute} replace />}
        />
      </Routes>
    </div>
  );
}

export default App;
