import React, { useEffect, useState } from "react";
import { liveQuizContext as LiveQuizContext } from "./context";
import LIVE_QUIZ_STATUS_CONSTANTS from "../constants/LiveQuizStatusConstants";
import { calculateTimestampDifferenceInSeconds } from "../functions/timeFunctions";

const LiveQuizState = (props) => {
  const [scheduledQuiz, setScheduledQuiz] = useState(null);
  const [stateVars, setStateVars] = useState({});
  const [totalQuestionsInQuiz, setTotalQuestionsInQuiz] = useState(0);

  const liveQuizScheduledTimeStamp = `${
    scheduledQuiz?.scheduleDate || "0001-01-01"
  }T${scheduledQuiz?.scheduledTime || "00:00:00"}`;

  const liveQuizStartTimeStamp = `${scheduledQuiz?.startDate || "0001-01-01"}T${
    scheduledQuiz?.startTime || "00:01:00"
  }`;

  const liveQuizDifferenceBetweenScheduledAndStartTime =
    calculateTimestampDifferenceInSeconds(
      liveQuizScheduledTimeStamp,
      liveQuizStartTimeStamp
    );

  const [liveQuizQASessionStartsInTime, setLiveQuizQASessionStartsInTime] =
    useState(liveQuizDifferenceBetweenScheduledAndStartTime);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = Date.now();
      const difference = +new Date(liveQuizStartTimeStamp) - now;
      setLiveQuizQASessionStartsInTime(
        isNaN(difference)
          ? Math.round(liveQuizDifferenceBetweenScheduledAndStartTime)
          : Math.round(difference / 1000)
      );
      if (difference <= 0) {
        clearInterval(timer);
      }
    }, 1000);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledQuiz]);

  useEffect(() => {
    setStateVars({
      isLiveQuizNotScheduled: scheduledQuiz === null,
      isLiveQuizScheduled:
        scheduledQuiz?.status === LIVE_QUIZ_STATUS_CONSTANTS.SCHEDULED,
      isLiveQuizLive: scheduledQuiz?.status === LIVE_QUIZ_STATUS_CONSTANTS.LIVE,
      isLiveQuizQASessionStarted: liveQuizQASessionStartsInTime <= 0,
      liveQuizQASessionStartsInTime,
      liveQuizScheduledDateTime: liveQuizScheduledTimeStamp,
      liveQuizStartDateTime: liveQuizStartTimeStamp,
      liveQuizDifferenceBetweenScheduledAndStartTime,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledQuiz, liveQuizQASessionStartsInTime]);

  return (
    <LiveQuizContext.Provider
      value={{
        ...stateVars,
        totalQuestionsInQuiz,
        setTotalQuestionsInQuiz,
        scheduledQuiz,
        setScheduledQuiz,
      }}
    >
      {props.children}
    </LiveQuizContext.Provider>
  );
};

export default LiveQuizState;
