import React from "react";
import PrivacyPolicyData from "../../data/PrivacyPolicyData";

const PrivacyPolicy = () => {
  return (
    <section className="rounded-[13px] tablet:p-[5.7em] mobile:p-[3.7em] p-[2.7em] text-blue-600 bg-white text-justify">
      <h1 className="text-[#45377E] tablet:text-[2.7em] mobile:text-[2em] text-[2em] font-[700] mb-[6px]">
        {PrivacyPolicyData.title}
      </h1>
      <ol className="text-[#45377ECC] list-decimal ml-6 tablet:text-[1.8em] mobile:text-[1.6em] text-[1.2em]">
        {PrivacyPolicyData.sections.map((section, index) => (
          <li key={index} className="space-y-2 mb-4">
            <p className="text-[#45377E] font-bold">{section.title}</p>
            {section.contentHeadline && (
              <p className="text-[#45377ECC] ">{section.contentHeadline}</p>
            )}
            {Array.isArray(section.content) ? (
              <ul className="list-disc ml-8">
                {section.content.map((bullet, idx) => (
                  <li key={idx} className="text-[#45377ECC]">
                    <strong>{bullet.split(": ")[0]}:</strong>{" "}
                    {bullet.split(": ")[1]}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="ml-4 text-[#45377ECC]">{section.content}</p>
            )}
          </li>
        ))}
      </ol>
      <p className="text-[#45377ECC] tablet:text-[1.8em] mobile:text-[1.6em] text-[1.2em] mt-4 ml-6">
        {PrivacyPolicyData.finalNotice}
      </p>
    </section>
  );
};

export default PrivacyPolicy;
