import { useContext, useState } from "react";
import { appContext } from "../../context/context";
import useHandleError from "../useHandleError";
import axios from "axios";
import {
  BASE_URL,
  ENDPOINT_UPDATE_USERNAME,
} from "../../constants/APIConstant";
import { useAlert } from "react-alert";

const useUpdateUserName = () => {
  const handleError = useHandleError();
  const alert = useAlert();
  const { saveUserName, token } = useContext(appContext);
  const [loading, setLoading] = useState(false);

  const updateUserName = (name) => {
    setLoading(true);
    axios
      .request({
        method: "POST",
        url: BASE_URL + ENDPOINT_UPDATE_USERNAME,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify({
          userName: name,
        }),
      })
      .then((response) => {
        saveUserName(name);
        const message = response?.data?.message;
        alert.success(message);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => setLoading(false));
  };
  return {updateUserName, loading};
};

export default useUpdateUserName;
