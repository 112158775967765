import { useAlert } from "react-alert";

const useHandleError = () => {
  const alert = useAlert();
  const handleError = (error) => {
    console.log(error);
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data ||
      error?.message ||
      error;
    alert.error(errorMessage);
  };
  return handleError;
};

export default useHandleError;
