import React, { useState } from "react";
import userAvatarIcon from "../../assets/svgs/userMobile.svg";
import trophyIcon from "../../assets/svgs/trophyMobile.svg";
import BackHeader from "./BackHeader";
import SideBarLinksMenu from "../SideBar/SideBarLinksMenu";
import UserProfileSection from "../SideBar/UserProfileSection";
import LogoutBtn from "../SideBar/LogoutBtn";
import PointsSection from "../Dashboard/PointsSection";
import { useNavigate } from "react-router-dom";
import { LeaderBoardRoute } from "../../constants/RouteConstants";
import LogoQuiz from "../../assets/images/LogoQuiz.png";

const DashBoardHeader = () => {
  const navigate = useNavigate();
  const navigateToLeaderBoard = () => navigate(LeaderBoardRoute);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const showSideBar = () => {
    setIsSidebarOpen(true);
  };
  const hideSideBar = () => {
    setIsSidebarOpen(false);
  };
  return (
    <>
      <div
        className={`bg-[#4D3E86] min-h-svh  text-white transition-width duration-500 absolute top-0 left-0 z-50 whitespace-nowrap overflow-x-hidden  ${
          isSidebarOpen ? "w-[100vw]" : "w-0"
        }`}
      >
        <MobileSidebar isOpen={isSidebarOpen} onClose={hideSideBar} />
      </div>
      <div className="bg-[#4D3E86] border-b-[1px] border-b-[#4D3E86]  flex justify-between items-center ">
        <div
          className="flex w-full justify-between items-center text-[2em] space-x-4 p-4 "
          // style={{
          //   background: "linear-gradient(90deg, #E55A9A 0%, #BA6BEB 100%)",
          // }}
        >
          <div onClick={showSideBar} className="cursor-pointer">
            <img
              src={userAvatarIcon}
              className="h-[2.5em] w-[2.5em]"
              alt="user"
            />
          </div>
          <img src={LogoQuiz} alt="" />
          <div className="cursor-pointer" onClick={navigateToLeaderBoard}>
            <img src={trophyIcon} alt="user" className="h-[2.4em] w-[2.4em]" />
          </div>
        </div>
      </div>
    </>
  );
};

const MobileSidebar = ({ isOpen, onClose }) => {
  return (
    <>
      <div className="flex flex-col justify-between  -h-[100%] ">
        <div className=" mb-24">
          <BackHeader onClick={onClose} isabsolute={true} />
          <div className="w-full flex justify-center mt-2 mb-2">
            <img className="" src={LogoQuiz} alt="" />
          </div>
          <UserProfileSection isOpen={isOpen} />
          <PointsSection />
          <SideBarLinksMenu isOpen={isOpen} onClose={onClose} />
        </div>
        <LogoutBtn isOpen={isOpen} />
        <div className="pointer-events-none text-transparent opacity-0">
          ...
        </div>
      </div>
    </>
  );
};

export default DashBoardHeader;
