import axios from "axios";
import useHandleError from "../useHandleError";
import {
  BASE_URL,
  ENDPOINT_GET_LIVE_QUIZ_RESULT,
} from "../../constants/APIConstant";
import { useContext } from "react";
import { appContext, liveQuizContext } from "../../context/context";

const useGetResults = () => {
  const { scheduledQuiz } = useContext(liveQuizContext);
  const id = scheduledQuiz?.id;
  const url = `${BASE_URL}${ENDPOINT_GET_LIVE_QUIZ_RESULT}/${id}`;
  const handleError = useHandleError();
  const { token, isLoggedIn } = useContext(appContext);
  const getResults = (callback) => {
    if (!isLoggedIn) {
      return;
    }
    axios
      .request({
        method: "GET",
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response?.data;
        // console.log(data);
        callback(data);
      })
      .catch((error) => {
        handleError(error);
      });
  };
  return getResults;
};

export default useGetResults;
