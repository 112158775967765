export const handleChange = (event, prevValue, setValue) => {
  if (!event.target) {
    return;
  }
  const { value } = event.target;

  //Guard clause to prevent Iteration if value is not Changed
  if (prevValue === value) {
    return;
  }

  if (!validateNotOnlySpaces(value)) {
    setValue("");
    return;
  }
  handleChangeFieldText(event, setValue);
};

const handleChangeFieldText = (event, setValue) => {
  const { value } = event.target;
  const pattern =
    event.target.getAttribute("validinput") ||
    event.target.getAttribute("pattern");
  // Check if there's no pattern or the value matches the pattern
  if (pattern) {
    if (new RegExp(pattern).test(value) || value === "") {
      // If the input is valid, update the form data
      const fixedindexesVal = event.target.getAttribute("fixedindexes");
      let foundAndUpdated = false;
      if (fixedindexesVal) {
        const fixedindexes = JSON.parse(fixedindexesVal);
        fixedindexes.forEach((item) => {
          if (value.length > item.index && value[item.index] !== item.char) {
            const formattedValue =
              value.substring(0, item.index) +
              item.char +
              value.substring(item.index);
            //console.log("Update Success\n New Value: " + formattedValue);
            setValue(formattedValue);
            foundAndUpdated = true;
          }
        });
      }
      if (!foundAndUpdated) {
        setValue(value);
      }
    }
  } else {
    // If the input is valid or empty, update the form data
    setValue(value);
  }
};

const validateNotOnlySpaces = (value) => {
  if (value.trim() === "") {
    return false; // Invalid, just spaces
  }
  return true; // Valid
};
