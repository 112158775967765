import React, { useEffect } from "react";
import TermsAndConditions from "../components/TermsAndConditions/TermsAndConditions";
import { logScreenView } from "../firebase";
function TermsConditions() {
  useEffect(() => {
    logScreenView("Terms & Conditions");
  }, []);
  return (
    <section className="tablet:p-[4em] mobile:p-[2em] p-[1em]">
      <TermsAndConditions />
    </section>
  );
}

export default TermsConditions;
