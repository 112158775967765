import React, { useContext, useState, useEffect } from "react";
import MSISDNInput from "../../reusable/MSISDNInput";
import CustomButton from "../../reusable/CustomButton";
import { deviceContext } from "../../context/context";
import useRequestOtp from "../../hooks/auth/useRequestOtp";
import isValidMsisdn from "../../functions/validateMsisdn";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import {
  PoliciesPageRoute,
  TermsAndConditionsPageRoute,
} from "../../constants/RouteConstants";

function GetOTP({ goToNextScreen, enrichedMsisdn }) {
  const alert = useAlert();
  const loaclStorageMsisdn = localStorage.getItem("msisdn");
  const [Msisdn, setMsisdn] = useState(
    loaclStorageMsisdn && loaclStorageMsisdn.length === 9
      ? loaclStorageMsisdn
      : ""
  );
  const { isMobileView } = useContext(deviceContext);
  const { requestOtp, loading } = useRequestOtp();
  const isMsisdnComplete = Msisdn.length === 9;

  useEffect(() => {
    if (enrichedMsisdn) {
      setMsisdn(enrichedMsisdn);
    }
  }, [enrichedMsisdn]);

  const requestOtpCallback = () => {
    goToNextScreen();
    localStorage.setItem("msisdn", Msisdn);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleClick();
  };

  const handleClick = () => {
    const msisdn = `923${Msisdn}`;
    if (isValidMsisdn(msisdn)) {
      requestOtp(msisdn, requestOtpCallback);
    } else {
      alert.error("Invalid mobile no.");
    }
  };

  return (
    <>
      {!isMobileView ? (
        <section className="whiteBox bg-white tablet:px-[8.7em] tablet:py-[5.8em] mobile:px-[5em] mobile:py-[3.4em]  tablet:max-w-[69.6em] rounded-[13px]">
          <h1 className="laptop:text-[3.5em] tablet:text-[2.5em] text-[1.8em] font-[900] text-[#45377E] text-center mb-[1.5em]">
            Enter your mobile number to receive OTP
          </h1>

          <form onSubmit={handleSubmit}>
            <MSISDNInput Msisdn={Msisdn} setMsisdn={setMsisdn} />
            <CustomButton
              text={loading ? "Loading..." : "GET OTP"}
              type={"submit"}
              width="100%"
              className={`mt-[2.6em]`}
              isDisabled={!isMsisdnComplete || loading}
            />
          </form>
          <h1 className="text-[#45377ECC] text-[1.8em] font-[500] text-center mt-[2em]">
            {process.env.REACT_APP_SUBSCRIPTION_RATE_MESSAGE}
          </h1>
          <h1 className="text-[#45377ECC] text-[1.8em] font-[500] text-center mt-[4em]">
            <Link
              className="cursor-pointer hover:underline"
              to={TermsAndConditionsPageRoute}
            >
              Terms & Conditions{" "}
            </Link>
            |{" "}
            <Link
              className="cursor-pointer hover:underline"
              to={PoliciesPageRoute}
            >
              {" "}
              Privacy Policy
            </Link>
          </h1>
        </section>
      ) : (
        <section className=" px-[2.6em]    tablet:max-w-[69.6em]  ">
          <h1 className="laptop:text-[3.5em] tablet:text-[2.5em] text-[1.7em] font-[500] text-[#FFFFFFB3] text-center mb-[1em]">
            Enter your mobile number to receive OTP
          </h1>
          <form onSubmit={handleSubmit}>
            <MSISDNInput Msisdn={Msisdn} setMsisdn={setMsisdn} />
            <CustomButton
              text={loading ? "Loading..." : "GET OTP"}
              type={"submit"}
              width="100%"
              className={`mt-[3em] !text-[1.5em] py-[1em]`}
              isDisabled={!isMsisdnComplete || loading}
            />
          </form>
          <h1 className="text-[#FFFFFF99] text-[1.5em] font-[400] text-center mt-[1.2em]">
            {process.env.REACT_APP_SUBSCRIPTION_RATE_MESSAGE}
          </h1>
          <h1 className="text-[#FFFFFFB3] text-[1.5em] font-[500] text-center mt-[2em] ">
            <Link
              className="cursor-pointer hover:underline"
              to={TermsAndConditionsPageRoute}
            >
              Terms & Conditions{" "}
            </Link>
            |{" "}
            <Link
              className="cursor-pointer hover:underline"
              to={PoliciesPageRoute}
            >
              {" "}
              Privacy Policy
            </Link>
          </h1>
        </section>
      )}
    </>
  );
}

export default GetOTP;
