import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import { Slug } from "./constants/Slug";
import reportWebVitals from "./reportWebVitals";
import AppState from "./context/AppState";
import DeviceState from "./context/DeviceState";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./components/Alerts/AlertTemplate";
import options from "./constants/AlertsOptions";
import StaticQuizState from "./context/StaticQuizState";
import LiveQuizState from "./context/LiveQuizState";

const root = ReactDOM.createRoot(document.getElementById("root"));
const useStrictMode = process.env.REACT_APP_USE_STRICT_MODE === "true";

const rootChildren = (
  <Router basename={Slug}>
    <DeviceState>
      <AlertProvider template={AlertTemplate} {...options}>
        <AppState>
          <StaticQuizState>
            <LiveQuizState>
              <App />
            </LiveQuizState>
          </StaticQuizState>
        </AppState>
      </AlertProvider>
    </DeviceState>
  </Router>
);

root.render(
  useStrictMode ? (
    <React.StrictMode>{rootChildren}</React.StrictMode>
  ) : (
    rootChildren
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
