// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };
const firebaseConfig = {
  apiKey: "AIzaSyA64bB8J5H9EyruxYKNk-m0AXj5hVkdcGM",
  authDomain: "iq-math-game-web.firebaseapp.com",
  projectId: "iq-math-game-web",
  storageBucket: "iq-math-game-web.appspot.com",
  messagingSenderId: "333530983860",
  appId: "1:333530983860:web:5b973ae8f3e485fbc6d6e1",
  measurementId: "G-KDW8FVQDEC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export function logScreenView(screenName) {
  logEvent(analytics, "screen_view", {
    screen_name: screenName,
  });
}
export default analytics;
