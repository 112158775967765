import React, { useContext, useState } from "react";
import { handleChange as handleChangeFunction } from "../functions/formFunctions";
import { deviceContext } from "../context/context";

const NameInput = ({ name, setName, classNameMobile, className }) => {
  const { isMobileView } = useContext(deviceContext);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (event) => {
    event.preventDefault();
    handleChangeFunction(event, name, setName);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return !isMobileView ? (
    <div
      className={`flex items-center borderInput rounded-[14px] p-5 tablet:text-[1.8em] text-[1.4em] text-[#999BBE] ${
        isFocused ? "focusInput" : ""
      }${className}`}
    >
      <input
        type="text"
        className={`outline-none text-gray-400 w-full  `}
        placeholder="Full Name"
        value={name}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        pattern="^[A-Za-z ]+$"
        maxLength={50}
        required={true}
      />
    </div>
  ) : (
    <div
      className={`flex items-center bg-[#5E4D96CC] rounded-[14px] p-5 text-[1.5em]  text-[#999BBE] ${
        isFocused ? "focusInput" : ""
      }${classNameMobile}`}
    >
      <input
        type="text"
        className={`outline-none bg-transparent text-gray-400 w-full `}
        placeholder="Name"
        value={name}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        pattern="^[A-Za-z ]+$"
        maxLength={50}
        required={true}
      />
    </div>
  );
};

export default NameInput;
