// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-container {
    position: relative;
    width: 150px;
    height: 150px;
}

.progress-circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.progress-circle::before {
    content: '';
    position: absolute;
    width: 85%;
    height: 85%;
    background-color: #fff;
    border-radius: 50%;
}

.progress-value {
    position: relative;
    font-size: 1.5rem;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/css/CircularProgressBar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":[".progress-container {\n    position: relative;\n    width: 150px;\n    height: 150px;\n}\n\n.progress-circle {\n    width: 100%;\n    height: 100%;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n}\n\n.progress-circle::before {\n    content: '';\n    position: absolute;\n    width: 85%;\n    height: 85%;\n    background-color: #fff;\n    border-radius: 50%;\n}\n\n.progress-value {\n    position: relative;\n    font-size: 1.5rem;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
