import React from "react";
import "../css/CircularProgressBar.css";

const CircularProgressBar = ({
  progress,
  progressColor,
  borderBgColor,
  bgColor,
  innerText,
}) => {
  return (
    // <div className="progress-container">
    //   <div
    //     className="progress-circle"
    //     style={{
    //       background: `conic-gradient(${progressColor || "#4caf50"} ${
    //         progress * 3.6
    //       }deg, ${bgColor || "#d3d3d3"} ${progress * 3.6}deg)`,
    //     }}
    //   >
    //     <span className="progress-value">{`${innerText || progress}%`}</span>
    //   </div>
    // </div>
    <div className="">
      <div className="relative size-16 ">
        <div
          className="w-full h-full rounded-full"
          style={{
            background: `conic-gradient(${progressColor || "#4caf50"} ${
              progress * 3.6
            }deg, ${borderBgColor || "#d3d3d3"} ${progress * 3.6}deg)`,
          }}
        ></div>
        <div
          style={{
            backgroundColor: bgColor,
          }}
          className="absolute inset-1 rounded-full"
        >
          <div className="h-full w-full flex items-center justify-center">
            {`${innerText || progress}`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircularProgressBar;
