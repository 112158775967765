import React from "react";
import "../css/LoadingScreen.css";
import LoadingComponent from "./LoadingComponent";

function LoadingScreen() {
  return (
    <div className="fixed w-screen h-svh bg-white bg-opacity-95">
      <LoadingComponent />
    </div>
  );
}

export default LoadingScreen;
