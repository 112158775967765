import React, { useContext, useEffect } from "react";
import Categories from "../components/staticQuiz/Categories";
import Levels from "../components/staticQuiz/Levels";
import QuestionScreen from "../components/staticQuiz/Questions";

import ResultScreen from "../components/staticQuiz/Result";
import { logEvent } from "firebase/analytics";
import analytics from "../firebase";
import { staticQuizContext } from "../context/context";
import StartQuizPopUp from "../components/staticQuiz/StartQuizPopUp";

const StaticQuiz = () => {
  const QUIZ_STEPS = [
    {
      step: 1,
      component: Categories,
    },
    {
      step: 2,
      component: Levels,
    },
    {
      step: 3,
      component: StartQuizPopUp,
    },
    {
      step: 4,
      component: QuestionScreen,
    },
    {
      step: 5,
      component: ResultScreen,
    },
  ];

  const { currentStep, setCurrentStep } = useContext(staticQuizContext);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const goToNextStep = () => {
    if (currentStep < QUIZ_STEPS.length) {
      setCurrentStep(QUIZ_STEPS[currentStep].step);
      scrollToTop();
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
      scrollToTop();
    }
  };

  useEffect(() => {
    if (currentStep === 4) {
      logEvent(analytics, "Daily_Quiz_Resut_Screen_Opened", {
        date: new Date().toISOString(),
      });
    }
  }, [currentStep]);

  const goToFirstStep = () => {
    setCurrentStep(1);
    scrollToTop();
  };

  const SelectedComponent = QUIZ_STEPS.filter(
    (step) => step.step === currentStep
  )[0].component;
  return (
    <section className="h-full">
      <SelectedComponent
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        goToFirstStep={goToFirstStep}
      />
    </section>
  );
};

export default StaticQuiz;
