import React, { useEffect, useState } from "react";
import { staticQuizContext as StaticQuizContext } from "./context";
import { useLocation } from "react-router-dom";
import { DailyQuizRoute } from "../constants/RouteConstants";

const StaticQuizState = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [quizKey, setQuizKey] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [quizDifficultyLevel, setQuizDifficultyLevel] = useState(null);
  const [staticQuizQuestions, setStaticQuizQuestions] = useState([]);
  const [selectedQuestionNo, setSelectedQuestionNo] = useState(0);
  const selectNextQuestion = () => {
    if (staticQuizQuestions.length > selectedQuestionNo + 1) {
      setSelectedQuestionNo((prev) => prev + 1);
    }
  };

  const [isStaticQuizMode, setIsStaticQuizMode] = useState(false);
  const startStaticQuizMode = () => {
    setIsStaticQuizMode(true);
  };

  const [userRanking, setUserRanking] = useState(null);

  const [noOfCorrectlyAnsweredQuestions, setNoOfCorrectlyAnsweredQuestions] =
    useState(0);
  const [
    noOfInCorrectlyAnsweredQuestions,
    setNoOfInCorrectlyAnsweredQuestions,
  ] = useState(0);

  const resetStaticQuizState = () => {
    setIsStaticQuizMode(false);
    setQuizKey(null);
    setSelectedCategories([]);
    setQuizDifficultyLevel(null);
    setSelectedCategories([]);
    setSelectedQuestionNo(0);
    setUserRanking(null);
    setNoOfCorrectlyAnsweredQuestions(0);
    setNoOfInCorrectlyAnsweredQuestions(0);
  };

  const location = useLocation();
  useEffect(() => {
    // This function will be called whenever the route changes
    if (location.pathname === DailyQuizRoute) {
      // console.log(location.pathname);
      setCurrentStep(1);
    }
  }, [location]);

  return (
    <StaticQuizContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        quizKey,
        setQuizKey,
        selectedCategories,
        setSelectedCategories,
        quizDifficultyLevel,
        setQuizDifficultyLevel,
        resetStaticQuizState,
        isStaticQuizMode,
        startStaticQuizMode,
        staticQuizQuestions,
        setStaticQuizQuestions,
        selectedQuestionNo,
        selectNextQuestion,
        userRanking,
        setUserRanking,
        noOfCorrectlyAnsweredQuestions,
        setNoOfCorrectlyAnsweredQuestions,
        noOfInCorrectlyAnsweredQuestions,
        setNoOfInCorrectlyAnsweredQuestions,
      }}
    >
      {props.children}
    </StaticQuizContext.Provider>
  );
};

export default StaticQuizState;
