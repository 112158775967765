import React, { useContext, useEffect, useRef } from "react";
import { deviceContext } from "../../context/context";

const OTPInput = ({ otp, setOtp }) => {
  const { isMobileView } = useContext(deviceContext);
  const inputRefs = useRef([]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const value = element.value;
    const newOtp = [...otp];

    if (value.length > 1) {
      for (let i = 0; i < value.length && i + index < otp.length; i++) {
        newOtp[i + index] = value[i];
      }
    } else {
      newOtp[index] = value;
    }
    setOtp(newOtp);
    const otpStr = newOtp.join("");

    // Focus next input
    if (element.value !== "" && otpStr.length < otp.length) {
      inputRefs.current[index + value.length].focus();
    } else if (element.value !== "" && otpStr.length - 1 < otp.length) {
      inputRefs.current[otpStr.length - 1].focus();
    } else {
      inputRefs.current[index].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      const newOtp = [...otp];
      if (newOtp[index] === "") {
        if (index !== 0) {
          newOtp[index - 1] = "";
          setOtp(newOtp);
          inputRefs.current[index - 1].focus();
        }
      } else {
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };

  // FOCUS FIRST FIELD (ON RENDER)
  useEffect(() => {
    const firstOtpInput = document.getElementById("otp-input-0");
    if (firstOtpInput) {
      firstOtpInput.focus();
    }
  }, []);

  return (
    <div className={`flex justify-center mb-4 `}>
      {otp.map((data, index) => (
        <input
          key={index}
          id={`otp-input-${index}`}
          type="tel"
          name="otp"
          autoComplete="one-time-code"
          maxLength="4"
          className={`w-12 h-12 m-2 text-center  rounded-[12px] focus:outline-none ${
            !isMobileView
              ? " text-[#45377ECC] text-[16px] bg-transparent border border-gray-300"
              : "text-[#FFFFFFB3] text-[14px] bg-[#5E4D96CC] "
          }`}
          value={data}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputRefs.current[index] = el)}
          onFocus={(e) => e.target.select()}
        />
      ))}
    </div>
  );
};

export default OTPInput;
