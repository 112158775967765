import { convertLocalDateTimeToTime, getCurrentTime } from "./timeFunctions";

export const scheduleTokenRefresh = (expirationTimeArray, refreshToken, bufferToRefreshTokenInSeconds) => {
    if (!expirationTimeArray) {
      return;
    }
    if (!Array.isArray(expirationTimeArray)) {
      expirationTimeArray = expirationTimeArray.split(",").map(Number);
    }
    expirationTimeArray[6] = 0;
    const expirationTime = convertLocalDateTimeToTime(expirationTimeArray);
    const currentTime = getCurrentTime();
    const timeRemainingToExpire = expirationTime - currentTime;
    const bufferTimeBeforeExpiration =
      timeRemainingToExpire - bufferToRefreshTokenInSeconds * 1000; // Currently 15 sceonds
    if (bufferTimeBeforeExpiration <= 0) {
      refreshToken();
    } else {
      setTimeout(refreshToken, bufferTimeBeforeExpiration);
      /*console.log(
        `Token will refresh in ${parseInt(
          bufferTimeBeforeExpiration / 1000
        )} seconds.`
      );*/
    }
  };