import React from "react";

const CustomButton = ({
  text,
  onClick,
  type,
  width,
  height,
  className,
  isDisabled,
  disabledBgOpacity,
}) => {
  return (
    <button
      onClick={isDisabled ? () => {} : onClick}
      type={type || "button"}
      className={`bg-custom-gradient text-white  py-[0.7em] rounded-[13px] text-[2em] font-[600] transition-transform duration-300 select-none ${
        isDisabled
          ? `${disabledBgOpacity || "opacity-80"} cursor-not-allowed`
          : " hover:scale-105 hover:opacity-90"
      } ${className}`}
      style={{ width: width, height: height }}
    >
      {text}
    </button>
  );
};

export default CustomButton;
