import React, { useState } from "react";
import useAddFeedback from "../../hooks/user/useAddFeedback";
import CustomButton from "../../reusable/CustomButton";

const SupportAndFeedback = () => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const addFeedback = useAddFeedback();
  const [feedback, setFeedback] = useState("");
  const handleFeedbackChange = (event) => {
    event.preventDefault();
    setFeedback(event.target.value);
  };

  const addFeedbackCallback = () => {
    setRating(0);
    setHover(0);
    setFeedback("");
  };

  const handleSubmit = () => {
    if (feedback.trim().length > 0) {
      addFeedback(rating, feedback, addFeedbackCallback);
    }
  };

  return (
    <section className="rounded-[13px] h-[600px]  tablet:p-[5.7em] mobile:p-[3.7em] p-[2.7em] bg-white">
      <div className="mb-4">
        <h2 className="text-[#45377E] font-[700] laptop:text-[2.7em] tablet:text-[2.4em] mobile:-[2.2em] text-[2em] mb-2">
          Rate Us
        </h2>
        <div className="flex">
          {[...Array(5)].map((star, index) => {
            index += 1;
            return (
              <button
                type="button"
                key={index}
                className={
                  index <= (hover || rating)
                    ? "text-yellow-500"
                    : "text-gray-300"
                }
                onClick={() => setRating(index)}
                onMouseEnter={() => setHover(index)}
                onMouseLeave={() => setHover(rating)}
              >
                <span className="star mobile:text-[5em] text-[4em] ">
                  &#9733;
                </span>
              </button>
            );
          })}
        </div>
      </div>
      <div className="mobile:mt-[6em] mt-[3em]">
        <h2 className="text-[#45377E] font-[700] laptop:text-[2.7em] tablet:text-[2.4em] mobile:-[2.2em] text-[2em] mb-2">
          Give Feedback
        </h2>
        <textarea
          className="w-full outline-none h-32 p-4 border mobile:text-[1.8em] text-[1.5em] text-[#45377ECC] border-gray-300 rounded-md"
          placeholder="Your Feedback"
          value={feedback}
          onChange={handleFeedbackChange}
        ></textarea>
      </div>
      <div>
        {/* <button
          onClick={handleSubmit}
          disabled={feedback.trim().length === 0}
          className="btn bg-gray-700 px-10 py-5 w-full rounded-xl mt-10"
        >
          Submit
        </button> */}
      </div>
      <div className="mt-[3em] text-center  tablet:flex">
        <CustomButton
          text="SUBMIT"
          className="!rounded-[10px] tablet:!text-[2em] mobile:!text-[1.8em] !text-[1.5em] font-[600] mobile:!py-[0.6em] mobile:!w-[13em] !w-[17em] !py-[0.8em] uppercase"
          onClick={handleSubmit}
          isDisabled={feedback.trim().length === 0 || rating === 0}
          disabledBgOpacity="opacity-70"
        />
      </div>
    </section>
  );
};

export default SupportAndFeedback;
