const TermsAndConditionsData = {
  title: "QuizWiz Terms and Conditions",
  sections: [
    {
      title: "Acceptance of Terms",
      content:
        "By accessing and using QuizWiz you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms please do not use our service.",
    },
    {
      title: "Account Registration",
      content:
        "To participate in quizzes and access certain features you must create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.",
    },
    {
      title: "Use of Service",
      content:
        "You agree to use QuizWiz for lawful purposes only. You must not use the service in any way that causes or may cause damage to the service or impairment of the availability or accessibility of the service.",
    },
    {
      title: "Quiz Participation",
      content: [
        "Daily Quizzes: Users can participate in daily quizzes and compete for incentives such as mobile data and other rewards.",
        "Battle Quizzes: Users can challenge others in real-time quiz battles. Winners will receive various prizes based on their performance.",
        "Fair Play: Cheating or any form of unfair practice is strictly prohibited. Users found engaging in such activities will be disqualified and may have their accounts suspended.",
      ],
    },
    {
      title: "Incentives and Rewards",
      content: [
        "Eligibility: Only registered users are eligible to receive rewards.",
        "Distribution: Rewards such as mobile data and other incentives will be distributed according to the rules specified for each quiz.",
        "Non-transferable: Rewards are non-transferable and cannot be exchanged for cash or other substitutes.",
      ],
    },
    {
      title: "Privacy Policy",
      content:
        "Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect use and disclose your personal information.",
    },
    {
      title: "Intellectual Property",
      content:
        "All content on QuizWiz including text graphics logos and software is the property of QuizWiz and is protected by applicable intellectual property laws. You may not reproduce distribute or create derivative works from any content without our express written permission.",
    },
    {
      title: "Limitation of Liability",
      content:
        'QuizWiz is provided on an "as is" and "as available" basis. We do not warrant that the service will be uninterrupted or error-free. To the fullest extent permitted by law QuizWiz disclaims all warranties express or implied regarding the service and your use thereof.',
    },
    {
      title: "Modifications to Terms",
      content:
        "We reserve the right to modify these Terms and Conditions at any time. Changes will be effective immediately upon posting on the QuizWiz website or app. Your continued use of the service after any such changes constitutes your acceptance of the new terms.",
    },
    {
      title: "Termination",
      content:
        "We may terminate or suspend your account and access to QuizWiz at any time without prior notice or liability for any reason including if you breach these Terms and Conditions.",
    },
    {
      title: "Governing Law",
      content:
        "These Terms and Conditions are governed by and construed in accordance with the laws of Pakistan without regard to its conflict of law principles.",
    },
  ],
  finalNotice:
    "By using QuizWiz you acknowledge that you have read understood and agree to be bound by these Terms and Conditions.",
};

export default TermsAndConditionsData;
