import { useContext } from "react";
import { appContext } from "../../context/context";
import useHandleError from "../useHandleError";
import axios from "axios";
import {
  BASE_URL,
  ENDPOINT_TEST_LIVE_QUIZ_ANSWER,
} from "../../constants/APIConstant";
import { LIVE_QUIZ_TIME_PER_QUESTION_IN_SECONDS } from "../../constants/EnvConstants";

const useTestLiveQuizAnswer = () => {
  const handleError = useHandleError();

  const { token } = useContext(appContext);
  const testLiveQuizAnswer = (
    liveQuizQuestionId,
    optionNumberSelected,
    timeTakenInMilliseconds,
    callback
  ) => {
    axios
      .request({
        method: "POST",
        url: BASE_URL + ENDPOINT_TEST_LIVE_QUIZ_ANSWER,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify({
          liveQuizQuestionId: liveQuizQuestionId,
          optionNumberSelected: optionNumberSelected,
          timeTakenInMilliseconds: timeTakenInMilliseconds,
          totalQuestionTimeInMilliseconds:
            LIVE_QUIZ_TIME_PER_QUESTION_IN_SECONDS * 1000,
        }),
      })
      .then((response) => {
        const data = response?.data;
        callback(data);
      })
      .catch((error) => {
        handleError(error);
      });
  };
  return testLiveQuizAnswer;
};

export default useTestLiveQuizAnswer;
