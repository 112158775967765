import { useContext } from "react";
import { appContext } from "../../context/context";
import useHandleError from "../useHandleError";
import axios from "axios";
import { BASE_URL, ENDPOINT_GET_POINTS } from "../../constants/APIConstant";

const useGetUserTotalScore = () => {
  const handleError = useHandleError();
  const { token, isLoggedIn } = useContext(appContext);
  const getUserTotalScore = (callback) => {
    if (!isLoggedIn) {
      return;
    }
    axios
      .request({
        method: "GET",
        url: BASE_URL + ENDPOINT_GET_POINTS,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response?.data;
        callback(data?.points);
      })
      .catch((error) => {
        handleError(error);
      });
  };
  return getUserTotalScore;
};

export default useGetUserTotalScore;
