import {
  IS_USER_AUTHORIZED_KEY,
  MSISDN_KEY,
  TOKEN_KEY,
  USERNAME_KEY,
} from "../constants/SessionStorageKeys";

const getValue = (key) => {
  const tokenStr = sessionStorage.getItem(key);
  if (!tokenStr) {
    return null;
  }
  return JSON.parse(tokenStr);
};

const saveValue = (key, value) => {
  if (!value) {
    return;
  }
  const valStr = JSON.stringify(value);
  sessionStorage.setItem(key, valStr);
};

// TOKEN
export const getToken = () => {
  return getValue(TOKEN_KEY);
};

export const saveToken = (value) => {
  saveValue(TOKEN_KEY, value);
};

export const deleteToken = () => {
  sessionStorage.removeItem(TOKEN_KEY);
};

// MSISDN
export const getMsisdn = () => {
  return getValue(MSISDN_KEY);
};

export const saveMsisdn = (value) => {
  saveValue(MSISDN_KEY, value);
};

export const deleteMsisdn = () => {
  sessionStorage.removeItem(MSISDN_KEY);
};

// UserName
export const getUserName = () => {
  return getValue(USERNAME_KEY);
};

export const saveUserName = (value) => {
  saveValue(USERNAME_KEY, value);
};

export const deleteUserName = () => {
  sessionStorage.removeItem(USERNAME_KEY);
};

// IS USER AUTHORIZED
export const getIsUserAuthorized = () => {
  return getValue(IS_USER_AUTHORIZED_KEY);
};

export const saveIsUserAuthorized = (value) => {
  saveValue(IS_USER_AUTHORIZED_KEY, value);
};

export const deleteIsUserAuthorized = () => {
  sessionStorage.removeItem(IS_USER_AUTHORIZED_KEY);
};
