import { useContext } from "react";
import { appContext, staticQuizContext } from "../../context/context";
import useHandleError from "../useHandleError";
import axios from "axios";
import {
  BASE_URL,
  ENDPOINT_TEST_STATIC_QUIZ_ANSWER,
} from "../../constants/APIConstant";
import { useAlert } from "react-alert";

const useTestStaticQuizAnswer = () => {
  const handleError = useHandleError();
  const alert = useAlert();
  const { token } = useContext(appContext);
  const {
    quizKey,
    setQuizKey,
    setNoOfCorrectlyAnsweredQuestions,
    setNoOfInCorrectlyAnsweredQuestions,
    setUserRanking,
  } = useContext(staticQuizContext);
  const testStaticQuizAnswer = (
    questionId,
    selectedOption,
    questionStartTimeMillis,
    answerSubmissionTimeMillis,
    callback
  ) => {
    if (!quizKey) {
      alert.info("Your Quiz is Ended.");
      return;
    }
    axios
      .request({
        method: "POST",
        url: BASE_URL + ENDPOINT_TEST_STATIC_QUIZ_ANSWER,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify({
          quizKey: quizKey,
          questionId: questionId,
          selectedOption: selectedOption,
          questionStartTimeMillis: questionStartTimeMillis,
          answerSubmissionTimeMillis: answerSubmissionTimeMillis,
        }),
      })
      .then((response) => {
        const data = response?.data;
        callback(data?.true, data?.quizFinished);
        setQuizKey(data?.quizKey);
        const noOfCorrectlyAnsweredQuestions = data?.correctAnswers;
        if (noOfCorrectlyAnsweredQuestions) {
          setNoOfCorrectlyAnsweredQuestions(noOfCorrectlyAnsweredQuestions);
        }
        const noOfInCorrectlyAnsweredQuestions = data?.incorrectAnswers;
        if (noOfInCorrectlyAnsweredQuestions) {
          setNoOfInCorrectlyAnsweredQuestions(noOfInCorrectlyAnsweredQuestions);
        }
        const ranking = data?.ranking;
        if (ranking) {
          setUserRanking(ranking);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };
  return testStaticQuizAnswer;
};

export default useTestStaticQuizAnswer;
