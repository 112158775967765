import React from "react";
import AboutUs from "../components/About/AboutUs";

function About() {
  return (
    <>
      <section className="tablet:p-[4em] mobile:p-[2em] p-[1em]">
        <AboutUs />
      </section>
    </>
  );
}

export default About;
