import React, { useEffect } from "react";
import Policies from "../components/Policies /Policies";
import { logScreenView } from "../firebase";
function OurPolicies() {
  useEffect(() => {
    logScreenView("Policies");
  }, []);
  return (
    <section className="tablet:p-[4em] mobile:p-[2em] p-[1em]">
      <Policies />
    </section>
  );
}

export default OurPolicies;
