// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading {
    --speed-of-animation: 1.32s;
    --gap: 6px;
    --first-color: #4c86f9;
    --second-color: #49a84c;
    --third-color: #f6bb02;
    --fourth-color: #f6bb02;
    --fifth-color: #2196f3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    gap: 6px;
    height: 100px;
   }
   
   .loading span {
    width: 4px;
    height: 50px;
    background: var(--first-color);
    animation: scale var(--speed-of-animation) ease-in-out infinite;
   }
   
   .loading span:nth-child(2) {
    background: var(--second-color);
    animation-delay: -0.8s;
   }
   
   .loading span:nth-child(3) {
    background: var(--third-color);
    animation-delay: -0.7s;
   }
   
   .loading span:nth-child(4) {
    background: var(--fourth-color);
    animation-delay: -0.6s;
   }
   
   .loading span:nth-child(5) {
    background: var(--fifth-color);
    animation-delay: -0.5s;
   }
   
   @keyframes scale {
    0%, 40%, 100% {
     transform: scaleY(0.05);
    }
   
    20% {
     transform: scaleY(1);
    }
   }`, "",{"version":3,"sources":["webpack://./src/css/loader-2.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,UAAU;IACV,sBAAsB;IACtB,uBAAuB;IACvB,sBAAsB;IACtB,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,QAAQ;IACR,aAAa;GACd;;GAEA;IACC,UAAU;IACV,YAAY;IACZ,8BAA8B;IAC9B,+DAA+D;GAChE;;GAEA;IACC,+BAA+B;IAC/B,sBAAsB;GACvB;;GAEA;IACC,8BAA8B;IAC9B,sBAAsB;GACvB;;GAEA;IACC,+BAA+B;IAC/B,sBAAsB;GACvB;;GAEA;IACC,8BAA8B;IAC9B,sBAAsB;GACvB;;GAEA;IACC;KACC,uBAAuB;IACxB;;IAEA;KACC,oBAAoB;IACrB;GACD","sourcesContent":[".loading {\n    --speed-of-animation: 1.32s;\n    --gap: 6px;\n    --first-color: #4c86f9;\n    --second-color: #49a84c;\n    --third-color: #f6bb02;\n    --fourth-color: #f6bb02;\n    --fifth-color: #2196f3;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100px;\n    gap: 6px;\n    height: 100px;\n   }\n   \n   .loading span {\n    width: 4px;\n    height: 50px;\n    background: var(--first-color);\n    animation: scale var(--speed-of-animation) ease-in-out infinite;\n   }\n   \n   .loading span:nth-child(2) {\n    background: var(--second-color);\n    animation-delay: -0.8s;\n   }\n   \n   .loading span:nth-child(3) {\n    background: var(--third-color);\n    animation-delay: -0.7s;\n   }\n   \n   .loading span:nth-child(4) {\n    background: var(--fourth-color);\n    animation-delay: -0.6s;\n   }\n   \n   .loading span:nth-child(5) {\n    background: var(--fifth-color);\n    animation-delay: -0.5s;\n   }\n   \n   @keyframes scale {\n    0%, 40%, 100% {\n     transform: scaleY(0.05);\n    }\n   \n    20% {\n     transform: scaleY(1);\n    }\n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
