import { useContext } from "react";
import { appContext } from "../../context/context";
import useHandleError from "../useHandleError";
import axios from "axios";
import {
  BASE_URL,
  ENDPOINT_GET_LEADERBOARD_DAILY,
} from "../../constants/APIConstant";

const useGetWeeklyStaticQuizLeaderBoard = () => {
  const handleError = useHandleError();
  const { token } = useContext(appContext);
  const getWeeklyStaticQuizLeaderBoard = (callback) => {
    axios
      .request({
        method: "GET",
        url: BASE_URL + ENDPOINT_GET_LEADERBOARD_DAILY,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        callback(response?.data);
      })
      .catch((error) => {
        handleError(error);
      });
  };
  return getWeeklyStaticQuizLeaderBoard;
};

export default useGetWeeklyStaticQuizLeaderBoard;
