import React from "react";
import LoadingScreen from "./LoadingScreen";
import LeaveQuizNavBar from "./LeaveQuizNavBar";

const WaitingForNextQuestion = () => {
  return (
    <div className="h-svh overflow-hidden text-white bg-[#45377E]">
      <LeaveQuizNavBar />
      <LoadingScreen text={"Waiting For Next Question"} />;
    </div>
  );
};

export default WaitingForNextQuestion;
