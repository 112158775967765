import React from "react";

const ToggleButton = ({ active, setActive }) => {
  const activeClass = "text-white border-b-[2px] border-b-[#d65757ce]";
  const inActiveClass = "text-[#FFFFFF66]";

  return (
    <div className="flex items-center justify-center laptop:text-[1.8em] tablet:text-[1.7em] mobile:text-[1.6em] text-[1.5em] font-[550]  px-7 ">
      <div className="flex mobile:space-x-[10em] space-x-[5em] border-b-[1px] border-b-[#FFFFFF0D]">
        <div
          className={`cursor-pointer ${
            active === "Daily" ? activeClass : inActiveClass
          }`}
          onClick={() => setActive("Daily")}
        >
          Daily
        </div>
        <div
          className={`cursor-pointer ${
            active === "Weekly" ? activeClass : inActiveClass
          }`}
          onClick={() => setActive("Weekly")}
        >
          Weekly
        </div>
      </div>
    </div>
  );
};

export default ToggleButton;
