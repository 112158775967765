import React from "react";

const LoadingComponent = () => {
  return (
    <div className="h-full w-full flex justify-center items-center">
      <div className="flex flex-col items-center space-y-6 mb-[10%]">
        <LoadingCircle />
        <h1 className="text-[23px] font-[600] text-[#8789B0]">Loading...</h1>
      </div>
    </div>
  );
};

const LoadingCircle = () => (
  <div className="rotation h-20 w-20 rounded-full bg-transparent border-4 border-transparent border-t-[#8789B0]" />
);

export default LoadingComponent;
