import React, { useEffect } from "react";
import MainContent from "../components/Dashboard/MainContent";
import analytics from "../firebase";
import { logEvent } from "firebase/analytics";

const Dashboard = () => {
  useEffect(() => {
    logEvent(analytics, "screen_view", {
      screen_name: "Dashboard",
      screen_type: "Page"
    });
  }, []);
  
  return (
    <>
      <MainContent />
    </>
  );
};

export default Dashboard;
