import React, { Suspense, lazy, useContext } from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";

import { appContext, liveQuizContext } from "./context/context";
import useScrollToTop from "./hooks/useScrollToTop";
import {
  DashboardRoute,
  AboutUsRoute,
  BaseRoute,
  HelpAndFAQRoute,
  SupportAndFeedbackRoute,
  TermsAndConditionsRoute,
  PoliciesRoute,
  NoMatchRoute,
  TermsAndConditionsPageRoute,
  PoliciesPageRoute,
  DailyQuizRoute,
  LeaderBoardRoute,
  LiveQuizRoute,
  LoginPageRoute,
  EmptyPageRoute,
  DemoAppRoute,
} from "./constants/RouteConstants";
import AuthRoute from "./components/AuthRoute";

// Lazy load pages
const Dashboard = lazy(() => import("./pages/Dashboard"));
const DemoDashboard = lazy(() => import("./pages/DemoDashboard"));
const Auth = lazy(() => import("./pages/Auth"));
const About = lazy(() => import("./pages/About"));
const HelpFAQ = lazy(() => import("./pages/HelpFAQ"));
const SupportFeedback = lazy(() => import("./pages/SupportFeedback"));
const TermsConditions = lazy(() => import("./pages/TermsConditions"));
const TermsConditionsPage = lazy(() => import("./pages/TermsConditions"));
const LeaderBoardPage = lazy(() => import("./pages/LeaderBoard"));
const OurPolicies = lazy(() => import("./pages/OurPolicies"));
const OurPoliciesPage = lazy(() => import("./pages/OurPolicies"));
const Home = lazy(() => import("./pages/Home"));
const StaticQuiz = lazy(() => import("./pages/StaticQuiz"));
const LiveQuiz = lazy(() => import("./pages/LiveQuiz"));
const EmptyPage = lazy(() => import("./pages/EmptyPage"));
const DemoHome = lazy(() => import("./pages/DemoHome"));
const DemoStaticQuiz = lazy(() => import("./pages/DemoStaticQuiz"));
const StartDemoMode = lazy(() => import("./pages/StartDemoMode"));

function App() {
  const { isLiveQuizLive } = useContext(liveQuizContext);
  const { isDemoMode } = useContext(appContext);

  const pageRoutes = [
    {
      path: LoginPageRoute,
      element: <Auth />,
      isProtected: false,
      isAvailable: true,
    },
    {
      path: TermsAndConditionsPageRoute,
      element: <TermsConditionsPage />,
      isProtected: false,
      isAvailable: true,
    },
    {
      path: PoliciesPageRoute,
      element: <OurPoliciesPage />,
      isProtected: false,
      isAvailable: true,
    },
    {
      path: LeaderBoardRoute,
      element: <LeaderBoardPage />,
      isProtected: true,
      isAvailable: true,
    },
    {
      path: LiveQuizRoute,
      element: <LiveQuiz />,
      isProtected: true,
      isAvailable: isLiveQuizLive,
    },
    {
      path: EmptyPageRoute,
      element: <EmptyPage />,
      isProtected: false,
      isAvailable: true,
    },
  ];

  const dashboardOutletRoutes = [
    { path: DashboardRoute, element: <Dashboard /> },
    { path: AboutUsRoute, element: <About /> },
    { path: HelpAndFAQRoute, element: <HelpFAQ /> },
    { path: SupportAndFeedbackRoute, element: <SupportFeedback /> },
    { path: TermsAndConditionsRoute, element: <TermsConditions /> },
    { path: PoliciesRoute, element: <OurPolicies /> },
    { path: DailyQuizRoute, element: <StaticQuiz /> },
  ];

  const demoDashboardOutletRoutes = [
    {
      path: `${DemoAppRoute}/`,
      element: <Navigate to={`${DemoAppRoute}${DashboardRoute}`} replace />,
    },
    { path: `${DemoAppRoute}${DashboardRoute}`, element: <DemoDashboard /> },
    { path: `${DemoAppRoute}${AboutUsRoute}`, element: <About /> },
    { path: `${DemoAppRoute}${HelpAndFAQRoute}`, element: <HelpFAQ /> },
    {
      path: `${DemoAppRoute}${SupportAndFeedbackRoute}`,
      element: <SupportFeedback />,
    },
    {
      path: `${DemoAppRoute}${TermsAndConditionsRoute}`,
      element: <TermsConditions />,
    },
    { path: `${DemoAppRoute}${PoliciesRoute}`, element: <OurPolicies /> },
    { path: `${DemoAppRoute}${DailyQuizRoute}`, element: <DemoStaticQuiz /> },
  ];

  useScrollToTop();

  return (
    <div className="h-svh-">
      <Routes>
        <Route element={<AuthRoute />}>
          <Route path={BaseRoute} element={<Home />}>
            {dashboardOutletRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    {route.element}
                  </Suspense>
                }
              />
            ))}
            <Route
              path={NoMatchRoute}
              element={<Navigate to={DashboardRoute} replace />}
            />
          </Route>
        </Route>

        {isDemoMode && process.env.REACT_APP_DEMO_MODE_ENABLED === "true" ? (
          <Route path={DemoAppRoute} element={<DemoHome />}>
            {demoDashboardOutletRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    {route.element}
                  </Suspense>
                }
              />
            ))}
          </Route>
        ) : (
          <Route path={DemoAppRoute} element={<StartDemoMode />} />
        )}

        {pageRoutes.map(
          (route) =>
            route.isAvailable &&
            (route.isProtected ? (
              <Route key={route.path} element={<AuthRoute />}>
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <Suspense fallback={<div>Loading...</div>}>
                      {route.element}
                    </Suspense>
                  }
                />
              </Route>
            ) : (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    {route.element}
                  </Suspense>
                }
              />
            ))
        )}

        <Route
          path={NoMatchRoute}
          element={<Navigate to={DashboardRoute} replace />}
        />
      </Routes>
    </div>
  );
}

export default App;
