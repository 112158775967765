import React, { useContext, /*useEffect,*/ useState } from "react";
import { deviceContext } from "../../context/context";
import CustomButton from "../../reusable/CustomButton";
import OTPInput from "./OTPInput";
import { OTP_SIZE } from "../../constants/EnvConstants";
import ReRequestOtp from "./ReRequestOtp";
import useVerifyOtp from "../../hooks/auth/useVerifyOtp";
import { useAlert } from "react-alert";
function VerifyOTP({ goToNextScreen }) {
  const [otp, setOtp] = useState(new Array(OTP_SIZE).fill(""));
  const { isMobileView } = useContext(deviceContext);
  const { verifyOtp, loading } = useVerifyOtp();
  const alert = useAlert();
  const isOtpFilled = otp.join("").length === OTP_SIZE;

  const callback = (userName) => {
    if (userName === null) {
      goToNextScreen();
    }
  };
  // Function to handle the click event of the verify button
  const handleClick = () => {
    const otpStr = otp.join(""); // Join the OTP array to form a string
    if (otpStr.length === OTP_SIZE) {
      verifyOtp(otpStr, callback); // Verify OTP if input is complete
    } else {
      alert.error("Invalid OTP");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleClick();
  };

  // useEffect to automatically verify OTP when input is complete
  /*useEffect(() => {
    const otpStr = otp.join(""); // Join the OTP array to form a string
    if (otpStr.length === OTP_SIZE) {
      verifyOtp(otpStr, callback); // Verify OTP if input is complete
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);*/
  // Conditional rendering based on device view type
  return (
    <>
      {!isMobileView ? (
        <section className="whiteBox bg-white tablet:px-[8.7em] tablet:py-[5.8em] mobile:px-[5em] mobile:py-[3.4em] tablet:w-[40vw]  tablet:max-w-[69.6em] rounded-[13px]">
          <h1 className="laptop:text-[3.5em] tablet:text-[2.5em] text-[1.8em] font-[900] text-[#45377E] text-center mb-[1.5em]">
            Enter OTP
          </h1>

          <form onSubmit={handleSubmit}>
            <OTPInput otp={otp} setOtp={setOtp} />
            <CustomButton
              text={loading ? "Verifying..." : "Verify"}
              type={"submit"}
              width="100%"
              className={`mt-[2.6em]`}
              isDisabled={!isOtpFilled || loading}
            />
          </form>
          <ReRequestOtp />
        </section>
      ) : (
        <section className=" px-[2.6em] w-[35vh]   tablet:max-w-[69.6em]  ">
          <h1 className="laptop:text-[3.5em] tablet:text-[2.5em] text-[1.7em] font-[500] text-[#FFFFFFB3] text-center mb-[1em]">
            Enter OTP
          </h1>

          <form onSubmit={handleSubmit}>
            <OTPInput otp={otp} setOtp={setOtp} />
            <CustomButton
              text={loading ? "Verifying..." : "Verify"}
              type={"submit"}
              width="100%"
              className={`mt-[3em] !text-[1.5em] py-[1em]`}
              isDisabled={!isOtpFilled || loading}
            />
          </form>
          <ReRequestOtp />
        </section>
      )}
    </>
  );
}

export default VerifyOTP;
