// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* css */
.cylinder-head {
  --large-value: 156.25%;
  --small-value: 150.943396226%;
  border-top-left-radius: var(--large-value) var(--small-value);
  border-top-right-radius: var(--large-value) var(--small-value);
  border-bottom-left-radius: var(--large-value) var(--small-value);
  border-bottom-right-radius: var(--large-value) var(--small-value);
  aspect-ratio: 110/43;
}
`, "",{"version":3,"sources":["webpack://./src/css/Cylinder.css"],"names":[],"mappings":"AAAA,QAAQ;AACR;EACE,sBAAsB;EACtB,6BAA6B;EAC7B,6DAA6D;EAC7D,8DAA8D;EAC9D,gEAAgE;EAChE,iEAAiE;EACjE,oBAAoB;AACtB","sourcesContent":["/* css */\n.cylinder-head {\n  --large-value: 156.25%;\n  --small-value: 150.943396226%;\n  border-top-left-radius: var(--large-value) var(--small-value);\n  border-top-right-radius: var(--large-value) var(--small-value);\n  border-bottom-left-radius: var(--large-value) var(--small-value);\n  border-bottom-right-radius: var(--large-value) var(--small-value);\n  aspect-ratio: 110/43;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
