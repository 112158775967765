import React from "react";
import defaultImage from "../../assets/categoryImages/default.png";

// Set up the require context for images
const images = require.context('../../assets/categoryImages', false, /\.(png|jpe?g|svg)$/);

const StaticQuizCategoryImage = ({ imageKey, children, className }) => {
  // Attempt to dynamically require the image
  let backgroundImage;

  try {
    // Use the context to dynamically require the image
    backgroundImage = images(`./${imageKey}.png`);
  } catch (err) {
    // If the image is not found, use the default image
    backgroundImage = defaultImage;
    console.log("Image not found, using default image.");
  }

  // Style object to use the image as background
  const style = {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover", // Adjusts the image to cover the container
    backgroundPosition: "center", // Centers the image
  };

  return (
    <div
    key-img={imageKey}
    className={className}
      style={style}
      onError={(e) => {
        // Fallback if the image fails to load
        e.target.style.backgroundImage = `url(${defaultImage})`;
      }}
    >
      {children}
    </div>
  );
};

export default StaticQuizCategoryImage;
