// src/components/Header.js
import React, { useContext } from "react";
import DashBoardHeader from "../Header/DashBoardHeader";
import BackHeader from "../Header/BackHeader";
import { DashboardRoute } from "../../constants/RouteConstants";
import { useLocation, useNavigate } from "react-router-dom";
import { deviceContext } from "../../context/context";
import PointsSection from "./PointsSection";

function Header() {
  const { isTabletView } = useContext(deviceContext);
  const location = useLocation();
  const navigate = useNavigate();
  const navigateToDashboard = () => {
    navigate(DashboardRoute);
  };

  if (isTabletView) {
    return location.pathname === DashboardRoute ? (
      <DashBoardHeader />
    ) : (
      <BackHeader onClick={navigateToDashboard} />
    );
  }
  return (
    <div className="bg-[#45377E] border-b-[1px] border-b-[#4D3E86] h-[7em]  flex justify-between items-center ">
      <PointsSection />
    </div>
  );
}

export default Header;
