import React, { useContext, useEffect, useState } from "react";
import Confetti from "react-confetti";
import successProfileIcon from "../../assets/images/successProfileIcon.png";
import failureProfileIcon from "../../assets/images/failureProfileIcon.png";
import trophyGrey from "../../assets/svgs/trophyGrey.svg";
import LeaveQuizNavBar from "./LeaveQuizNavBar";
import LoadingScreen from "./LoadingScreen";

import { LIVE_QUIZ_MINIMUM_PERCENTAGE_TO_PASS } from "../../constants/EnvConstants";
import { useNavigate } from "react-router-dom";
import { LeaderBoardRoute } from "../../constants/RouteConstants";
import useGetResults from "../../hooks/liveQuiz/useGetResults";
import { appContext } from "../../context/context";

const ResultScreen = () => {
  const { isLoggedIn } = useContext(appContext);
  const getResults = useGetResults();
  const navigate = useNavigate();
  const navigateToLeaderBoard = () => navigate(LeaderBoardRoute);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [showConfetti, setShowConfetti] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [liveResultConstants, setliveResultConstants] = useState({});
  const totalQuestionsInQuiz = liveResultConstants?.totalQuestionsInQuiz || 0;

  useEffect(() => {
    setTimeout(() => {
      getResults((data) => {
        setliveResultConstants(data);
        setIsLoading(false);
      });
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (showConfetti) {
      const confettiTimer = setTimeout(() => {
        setShowConfetti(false);
      }, 8000); // Stop confetti after 8 seconds
      return () => clearTimeout(confettiTimer);
    }
  }, [showConfetti]);

  const percentage =
    (liveResultConstants.correctAnswerCount * 100) / totalQuestionsInQuiz;
  const isPassed = percentage >= LIVE_QUIZ_MINIMUM_PERCENTAGE_TO_PASS;

  if (isLoading) {
    return (
      <div className="bg-[#45377E] text-white h-full">
        <LoadingScreen text="Fetching your results..." />
      </div>
    );
  }

  const dataClass1 =
    "flex flex-col items-center bg-[#5E4D96CC] rounded-[13px] mobile:px-[6.7em] mobile:py-[1.4em] px-[4em] py-[1em]";
  const dataClass2 =
    "laptop:text-[4em] tablet:text-[3.8em] mobile:text-[3.5em] text-[3.3em] font-[800]";
  const dataClass3 =
    "laptop:text-[2em] tablet:text-[1.7em] mobile:text-[1.5em] text-[1.3em] text-[#FFFFFF80]";

  return (
    <div className="flex flex-col items-center text-white min-h-svh bg-[#45377E] overflow-y-auto w-[100%] mobile:pb-0 pb-14">
      {isPassed && showConfetti && (
        <Confetti
          width={windowWidth}
          height={windowHeight}
          gravity={0.04} // Adjust this value to control the speed
        />
      )}
      <LeaveQuizNavBar title={"Back to Home"} />
      <div className="flex flex-col items-center mobile:mt-[10em] mt-[3em]">
        <img
          className={`${
            isPassed ? "w-[15em] h-[15em]" : "w-[12em] h-[12em]"
          } object-contain mb-8`}
          src={isPassed ? successProfileIcon : failureProfileIcon}
          alt="Profile"
        />
        <h1 className="laptop:text-[4.2em] tablet:text-[4.1em] mobile:text-[4em] text-[3.9em] font-[800] mb-[0.2em]">
          {isPassed ? "You did it!" : "Let's try again!"}
        </h1>
        {liveResultConstants.rank === 1 && !isPassed ? (
          <></>
        ) : (
          <p className="laptop:text-[2em] tablet:text-[1.8em] mobile:text-[1.6em] text-[1.5em] mb-[2.5em]">
            Your ranking is{" "}
            <span className="text-[#FF43BB] font-[700]">
              {liveResultConstants.rank}
            </span>{" "}
            on leaderboard!
          </p>
        )}
        <div className="grid grid-cols-3 px-2 gap-x-6 mb-9">
          <div className={dataClass1}>
            <span className={dataClass2}>
              {liveResultConstants.correctAnswerCount || 0}
            </span>
            <span className={dataClass3}>Correct</span>
          </div>
          <div className={dataClass1}>
            <span className={dataClass2}>
              {liveResultConstants.incorrectAnswerCount || 0}
            </span>
            <span className={dataClass3}>Wrong</span>
          </div>
          <div className={dataClass1}>
            <span className={dataClass2}>
              {totalQuestionsInQuiz - liveResultConstants.totalAnswersGiven}
            </span>
            <span className={dataClass3}>Unattended</span>
          </div>
        </div>
        <p className="laptop:text-[2em] tablet:text-[1.7em] mobile:text-[1.5em] text-[1.3em] text-[#FFFFFF99]">
          Total Questions:{" "}
          <span className="font-[700]">{totalQuestionsInQuiz || 0}</span>
        </p>
        <div className="mobile:mt-12 mt-48 flex flex-col items-center space-y-10">
          <div className="flex items-center space-x-2 opacity-60">
            <img
              className="object-contain mobile:w-auto w-[3em]"
              src={trophyGrey}
              alt=""
            />
            <button
              onClick={navigateToLeaderBoard}
              className=" laptop:text-[2em] tablet:text-[1.7em] mobile:text-[1.5em] text-[1.5em] text-[#FFFFFFB3] hover:underline "
            >
              Go To Leaderboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultScreen;
