import React from "react";
import "../../css/dot-flashing.css";

const LoadingScreen = ({ children, text }) => {
  return (
    <div className="  h-full w-full flex justify-center items-center">
      <div className="space-y-8">
        <div className="text-[1.6em] text-center">{text || "Loading"}</div>
        <div className="w-full flex justify-center">
          <div className="dot-flashing"></div>
        </div>
        {children ? (
          <div className="w-full flex justify-center">{children}</div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default LoadingScreen;
