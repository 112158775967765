import React from "react";
import toggleBtn from "../assets/svgs/toggleBtn.svg";
import LogoQuiz from "../assets/images/LogoQuiz.png";
import SideBarLinksMenu from "./SideBar/SideBarLinksMenu";
import UserProfileSection from "./SideBar/UserProfileSection";
import LogoutBtn from "./SideBar/LogoutBtn";
function Sidebar({ isOpen, setIsOpen }) {
  return (
    <div
      className={` transition-width duration-300  pb-[3.2em] ${
        isOpen ? "w-[33em] min-w-[33em]" : "w-[7em] "
      } bg-[#594991] min-h-full flex flex-col`}
    >
      <div className=" flex  items-center space-x-[2em] px-[2em] pt-[1.9em] mb-4">
        <img
          src={toggleBtn}
          alt="Toggle Button"
          className="cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        />
        <div
          className={`overflow-hidden ${
            isOpen ? "max-w-xs opacity-100" : "max-w-0 opacity-0"
          } transition-opacity transition-max-width duration-500 ease-in-out`}
        >
          {/* <h1 className="text-[2.6em] font-[600] whitespace-nowrap">
            Quiz App
          </h1> */}
          <img src={LogoQuiz} alt="" />
        </div>
      </div>
      <UserProfileSection isOpen={isOpen} />
      <SideBarLinksMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => {}}
      />
      <LogoutBtn isOpen={isOpen} />
    </div>
  );
}

export default Sidebar;
