import axios from "axios";
import { BASE_URL, ENDPOINT_REFRESH_TOKEN } from "../../constants/APIConstant";
import useHandleError from "../useHandleError";

const useRefreshToken = () => {
  const handleError = useHandleError();
  const refreshToken = (token, callback) => {
    axios
      .request({
        method: "GET",
        url: BASE_URL + ENDPOINT_REFRESH_TOKEN,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        callback(response?.data);
      })
      .catch((error) => {
        handleError(error);
      });
  };
  return refreshToken;
};

export default useRefreshToken;
