// src/components/MainContent.js
import React, { useEffect } from "react";
// import landingImage from "../../assets/images/landing.png";
import staticQuizTile from "../../assets/images/staticQuizTile.png";
import liveQuizTile from "../../assets/images/liveQuizTile.png";
// eslint-disable-next-line
import CustomButton from "../../reusable/CustomButton";
import { useNavigate } from "react-router-dom";
import { DailyQuizRoute, LiveQuizRoute } from "../../constants/RouteConstants";
import Countdown from "../../reusable/Countdown";
import { useContext } from "react";
import {
  appContext,
  deviceContext,
  liveQuizContext,
} from "../../context/context";
import useGetScheduledQuiz from "../../hooks/liveQuiz/useGetScheduledQuiz";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import bannerImage0 from "../../assets/bannerImages/banner0.png";
import bannerImage1 from "../../assets/bannerImages/banner1.png";
import bannerImage2 from "../../assets/bannerImages/banner2.png";

import mobileBannerImage0 from "../../assets/bannerImages/mobileBanner0.svg";
import mobileBannerImage1 from "../../assets/bannerImages/mobileBanner1.svg";
import mobileBannerImage2 from "../../assets/bannerImages/mobileBanner2.svg";
import { logEvent } from "firebase/analytics";
import analytics from "../../firebase";

function MainContent() {
  const { isTabletView } = useContext(deviceContext);
  const navigate = useNavigate();
  const navigateToDailyQuiz = () => {
    logEvent(analytics, "Daily_Quiz_Button_Clicked", {
      date: new Date().toISOString(),
    });
    navigate(DailyQuizRoute);
  };
  const navigateToLiveQuiz = () => {
    logEvent(analytics, "Live_Quiz_Button_Clicked", {
      date: new Date().toISOString(),
    });
    navigate(LiveQuizRoute);
  };
  const {
    isLiveQuizNotScheduled,
    isLiveQuizScheduled,
    isLiveQuizLive,
    liveQuizScheduledDateTime,
    setScheduledQuiz,
  } = useContext(liveQuizContext);

  const { isLoggedIn } = useContext(appContext);
  const getScheduledLiveQuiz = useGetScheduledQuiz();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getScheduledLiveQuiz(setScheduledQuiz), [isLoggedIn]);
  const carouselItems = [
    { image: bannerImage0, mobileImage: mobileBannerImage0 },
    { image: bannerImage1, mobileImage: mobileBannerImage1 },
    { image: bannerImage2, mobileImage: mobileBannerImage2 },
  ];
  return (
    <div
      className="flex-grow items-center flex flex-col  "
      /*style={{
        backgroundImage: `url(${landingImage})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
      }}*/
    >
      <Carousel
        autoPlay={true} // Enable auto-scrolling
        interval={2500} // Set the interval to 3000ms (3 seconds)
        infiniteLoop={true} // Enable infinite looping
        showStatus={false} // Hide the status
        showArrows={false} // Hide arrows
        showIndicators={true} // Hide indicators
        showThumbs={false} // Hide thumbnails
        dynamicHeight={false} // Disable dynamic height
      >
        {carouselItems
          .slice(
            0,
            process.env.REACT_APP_NO_OF_IMAGES_TO_SHOW_IN_DASHBOARD_CAROUSEL
          )
          .map((carouselItem, i) => (
            <div className="flex justify-center items-center" key={i}>
              <img
                src={
                  isTabletView ? carouselItem.mobileImage : carouselItem.image
                }
                className={`object-contain w-full h-auto ${
                  isTabletView
                    ? "mobile-carousel-image"
                    : "desktop-carousel-image"
                }`} // change added
                alt=""
              />
            </div>
          ))}
      </Carousel>

      <div className="  w-[92%]">
        <div className=" w-full  sm:mx-[6em]   ">
          {/* <h1 className="laptop:text-[5.2em] tablet:text-[4.5em] mobile:text-[3.5em] text-[3em] text-center text-[#FFFFFF] font-[600]">
            Where Fun and Knowledge Meets
          </h1> */}
          <div
            className="bg-white text-black px-[6.1em]  h-[20.8em] rounded-[1.3em] shadow-lg relative mt-[3.6em] flex "
            style={{
              backgroundImage: `url(${staticQuizTile})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "center",
            }}
          >
            <div className="  rounded-[13px]  flex mobile:flex-row flex-col mobile:justify-between justify-center items-center w-full mobile:text-left text-center ">
              <div>
                <h2 className="laptop:text-[4em] tablet:text-[3.5em] mobile:text-[3em] text-[2.7em] text-[#000640] font-semibold ">
                  Daily Quiz
                </h2>
                <p className="text-[#000640B3] mobile:text-[1.8em] text-[1.5em] ">
                  Play daily quiz and win prizes!
                </p>
              </div>
              <CustomButton
                text="Click To Play"
                onClick={navigateToDailyQuiz}
                className={
                  "font-[600] laptop:!text-[2em] mobile:w-auto w-[17em] mobile:!mt-0 !mt-8 tablet:!text-[1.5em] mobile:!text-[1.3em] !text-[1.5em] px-[1.5em] mobile:!py-[1em] !py-[1.2em] uppercase"
                }
              />
              {/* <h1 className="laptop:text-[3em]  tablet:text-[3em] mobile:text-[2.5em] text-[2em] text-nowrap font-[800] text-black opacity-70 mobile:mt-0 mt-4">
                COMING SOON...
              </h1> */}
            </div>
          </div>
        </div>

        {/* Live Quiz */}
        <div
          className="bg-white text-black px-[6.1em]  mobile:min-h-[20.8em]- max-h-[20.3em] min-h-[29em]- rounded-[1.3em] shadow-lg relative mt-[2em] flex "
          style={{
            backgroundImage: `url(${liveQuizTile})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "right",
          }}
        >
          <div className="  rounded-[13px] py-10 flex mobile:flex-row flex-col mobile:justify-between justify-center items-center w-full mobile:text-left text-center gap-x-16">
            <div className="">
              <h2 className="laptop:text-[4em] tablet:text-[3.5em] mobile:text-[3em] text-[2.7em] text-[#000640] font-semibold ">
                Live Quiz
              </h2>
              <p className="text-[#000640B3] mobile:text-[1.8em] text-[1.5em] ">
                Play weekly live quiz and win prizes!
              </p>
            </div>
            {isLiveQuizNotScheduled ? (
              <h1 className="laptop:text-[3em]  tablet:text-[3em] mobile:text-[2.5em] text-[2em] text-nowrap font-[800] text-black opacity-70 mobile:mt-0 mt-4">
                COMING SOON...
              </h1>
            ) : isLiveQuizScheduled ? (
              <Countdown targetDate={liveQuizScheduledDateTime} />
            ) : isLiveQuizLive ? (
              <CustomButton
                text="Click To Play"
                onClick={navigateToLiveQuiz}
                className={
                  "font-[600] laptop:!text-[2em] mobile:w-auto w-[17em] mobile:!mt-0 !mt-8 tablet:!text-[1.5em] mobile:!text-[1.3em] !text-[1.5em] px-[1.5em] mobile:!py-[1em] !py-[1.2em] uppercase"
                }
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainContent;
