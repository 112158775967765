const POSITION = process.env.REACT_APP_ALERT_POSITION;
const TRANSITION = process.env.REACT_APP_ALERT_TRANSITION_STYLE;
const DISPLAY_TIME_IN_MILLISECONDS = +process.env.REACT_APP_ALERT_DISPLAY_TIME_IN_MILLISECONDS;

const options = {
  position: POSITION,
  timeout: DISPLAY_TIME_IN_MILLISECONDS,
  offset: "30px",
  transition: TRANSITION,
};

export default options;
