// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */

 :root{
  --dot-flashing-color-1: #ffffff;
  --dot-flashing-color-2: #ffffff33;
 }
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--dot-flashing-color-1);
  color: var(--dot-flashing-color-1);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--dot-flashing-color-1);
  color: var(--dot-flashing-color-1);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--dot-flashing-color-1);
  color: var(--dot-flashing-color-1);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: var(--dot-flashing-color-1);
  }
  50%, 100% {
    background-color: var(--dot-flashing-color-2);
  }
}`, "",{"version":3,"sources":["webpack://./src/css/dot-flashing.css"],"names":[],"mappings":"AAAA;;;;EAIE;;CAED;EACC,+BAA+B;EAC/B,iCAAiC;CAClC;AACD;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,6CAA6C;EAC7C,kCAAkC;EAClC,oDAAoD;EACpD,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,qBAAqB;EACrB,kBAAkB;EAClB,MAAM;AACR;AACA;EACE,WAAW;EACX,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,6CAA6C;EAC7C,kCAAkC;EAClC,6CAA6C;EAC7C,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,6CAA6C;EAC7C,kCAAkC;EAClC,6CAA6C;EAC7C,mBAAmB;AACrB;;AAEA;EACE;IACE,6CAA6C;EAC/C;EACA;IACE,6CAA6C;EAC/C;AACF","sourcesContent":["/**\n * ==============================================\n * Dot Flashing\n * ==============================================\n */\n\n :root{\n  --dot-flashing-color-1: #ffffff;\n  --dot-flashing-color-2: #ffffff33;\n }\n.dot-flashing {\n  position: relative;\n  width: 10px;\n  height: 10px;\n  border-radius: 5px;\n  background-color: var(--dot-flashing-color-1);\n  color: var(--dot-flashing-color-1);\n  animation: dot-flashing 1s infinite linear alternate;\n  animation-delay: 0.5s;\n}\n.dot-flashing::before, .dot-flashing::after {\n  content: \"\";\n  display: inline-block;\n  position: absolute;\n  top: 0;\n}\n.dot-flashing::before {\n  left: -15px;\n  width: 10px;\n  height: 10px;\n  border-radius: 5px;\n  background-color: var(--dot-flashing-color-1);\n  color: var(--dot-flashing-color-1);\n  animation: dot-flashing 1s infinite alternate;\n  animation-delay: 0s;\n}\n.dot-flashing::after {\n  left: 15px;\n  width: 10px;\n  height: 10px;\n  border-radius: 5px;\n  background-color: var(--dot-flashing-color-1);\n  color: var(--dot-flashing-color-1);\n  animation: dot-flashing 1s infinite alternate;\n  animation-delay: 1s;\n}\n\n@keyframes dot-flashing {\n  0% {\n    background-color: var(--dot-flashing-color-1);\n  }\n  50%, 100% {\n    background-color: var(--dot-flashing-color-2);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
