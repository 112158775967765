import React from "react";
import LeaderBoardComponent from "../components/leaderboard/LeaderBoard";

const LeaderBoard = () => {
  return (
    <section>
      <LeaderBoardComponent />
    </section>
  );
};

export default LeaderBoard;
