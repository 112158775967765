import React from "react";

const Tooltip = ({
  text,
  maxWidth = "",
  textColor = "text-white",
  bgColor = "bg-gray-800",
  bgTipColor = "border-t-gray-800",
}) => {
  return (
    <div className={``}>
      <div
        className={`relative mb-4 ${maxWidth} ${bgColor} ${textColor} text-center   py-[0.8em] px-[1em] mobile:font-[600] font-[550] laptop:text-[2em]  mobile:text-[1.6em] text-[1.5em]  rounded-[10px] shadow-lg `}
      >
        <div
          className={`absolute top-full left-1/2 transform- -translate-x-1/2 border-8 border-transparent ${bgTipColor}`}
        ></div>
        {text}
      </div>
    </div>
  );
};

export default Tooltip;
