//All imports
import React from "react";
import "../../css/Cylinder.css";

const Cylinder = ({
  headColor,
  gradientColor1,
  gradientColor1Percentage,
  gradientColor2,
  gradientColor2Percentage,
  minHeightClass,
  text,
  textColor,
}) => {
  return (
    <div className={"relative w-[26vw] mobile:w-[14em] tablet:w-[16em] mt-40"}>
      <div
        style={{
          backgroundColor: headColor || "#E6DCFF",
        }}
        className="w-full  cylinder-head absolute z-20 translate-y-[-50%]"
      ></div>
      <div
        className={`${
          minHeightClass || "min-h-[50vh]"
        } w-full flex justify-center items-start bg-opacity-80`}
        style={{
          backgroundImage: `linear-gradient(180deg, ${
            gradientColor1 || "#d0c0fa"
          } ${gradientColor1Percentage || "0%"}, ${
            gradientColor2 || "#45377e"
          } ${gradientColor2Percentage || "100%"}
          )`,
        }}
      >
        <div
          className="mt-[39px] text-[10vw] mobile:text-[8.7em] tablet:text-[10.3em] laptop:text-[11.8em] font-[800]"
          style={{ color: textColor || "#FFFFFF" }}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

export default Cylinder;
