import React from "react";
import AboutUsData from "../../data/AboutUsData";

const AboutUs = () => {
  return (
    <section className="rounded-[13px] tablet:p-[5.7em] mobile:p-[3.7em] p-[2.7em] text-blue-600 bg-white text-justify">
      <h1 className="text-[#45377E]  tablet:text-[2.7em] mobile:text-[2em] text-[2em] font-[700] mb-[6px]">
        {AboutUsData.title}
      </h1>

      <p className="text-[#45377ECC] tablet:text-[1.8em] mobile:text-[1.6em] text-[1.2em]">
        {AboutUsData.welcome}
      </p>
      <br />
      <h2 className="text-[#45377E]  tablet:text-[2.7em] mobile:text-[2em] text-[2em] font-[700] mb-[6px]">
        {AboutUsData.missionTitle}
      </h2>
      <p className="text-[#45377ECC] tablet:text-[1.8em] mobile:text-[1.6em] text-[1.2em]">
        {AboutUsData.mission}
      </p>
      <br />
      <h2 className="text-[#45377E]  tablet:text-[2.7em] mobile:text-[2em] text-[2em] font-[700] mb-[6px]">
        {AboutUsData.featuresTitle}
      </h2>
      <ul className="text-[#45377ECC] list-disc ml-6 tablet:text-[1.8em] mobile:text-[1.6em] text-[1.2em]">
        {AboutUsData.features.map((feature, index) => (
          <li key={index}>
            <strong>{feature.split(": ")[0]}:</strong> {feature.split(": ")[1]}
          </li>
        ))}
      </ul>
      <br />
      <h2 className="text-[#45377E]  tablet:text-[2.7em] mobile:text-[2em] text-[2em] font-[700] mb-[6px]">
        {AboutUsData.whyChooseUsTitle}
      </h2>
      <p className="text-[#45377ECC] tablet:text-[1.8em] mobile:text-[1.6em] text-[1.2em]">
        {AboutUsData.whyChooseUs}
      </p>
    </section>
  );
};

export default AboutUs;
