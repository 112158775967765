import { useContext, useState } from "react";
import { appContext } from "../../context/context";
import useHandleError from "../useHandleError";
import axios from "axios";
import { BASE_URL, ENDPOINT_VERIFY_OTP } from "../../constants/APIConstant";
import { useAlert } from "react-alert";

const useVerifyOtp = () => {
  const appState = useContext(appContext);
  const alert = useAlert();
  const handleError = useHandleError();
  const [loading, setLoading] = useState(false);
  const verifyOtp = async (otp, callback) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}${ENDPOINT_VERIFY_OTP}`,
        {
          msisdn: appState.msisdn,
          otp,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { message, tokenObj, userName } = response.data;
      const isUserSubscribed =
        tokenObj?.tokenSubscriptionVerified ||
        tokenObj?.isTokenSubscriptionVerified;

      appState.saveToken(tokenObj);
      if (userName) {
        appState.saveUserName(userName);
      }
      appState.saveUserAuthorized(isUserSubscribed);

      alert.success(message);
      callback(userName);
    } catch (error) {
      const FAILED_TO_SUBSCRIBE_RESPONSE_CODE = 424;
      if (error?.response?.status === FAILED_TO_SUBSCRIBE_RESPONSE_CODE) {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  return { verifyOtp, loading };
};

export default useVerifyOtp;
