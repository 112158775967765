// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rotation{
    animation: rotate-animation infinite 2s linear;
}
@keyframes rotate-animation {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(270deg);
    }
    50% {
      transform: rotate(450deg);
    }
    100% {
      transform: rotate(720deg);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/css/LoadingScreen.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C;AAClD;AACA;IACI;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;IACA;MACE,yBAAyB;IAC3B;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".rotation{\n    animation: rotate-animation infinite 2s linear;\n}\n@keyframes rotate-animation {\n    0% {\n      transform: rotate(0deg);\n    }\n    30% {\n      transform: rotate(270deg);\n    }\n    50% {\n      transform: rotate(450deg);\n    }\n    100% {\n      transform: rotate(720deg);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
