import React from "react";
import "../css/loader-2.css";

const Loader2 = () => {
  return (
    <div className="loading">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default Loader2;
