import React, { useEffect, useRef, useState } from "react";
import { appContext as AppContext } from "./context";
import {
  getToken as getTokenFromSessionStorage,
  saveToken as saveTokenInSessionStorege,
  deleteToken as deleteTokenFromSessionStorage,
  getMsisdn as getMsisdnFromSessionStorage,
  saveMsisdn as saveMsisdnInSessionStorege,
  deleteMsisdn as deleteMsisdnFromSessionStorage,
  getUserName as getUserNameFromSessionStorage,
  saveUserName as saveUserNameInSessionStorege,
  deleteUserName as deleteUserNameFromSessionStorage,
  getIsUserAuthorized as getIsUserAuthorizedFromSessionStorage,
  saveIsUserAuthorized as saveIsUserAuthorizedInSessionStorage,
  deleteIsUserAuthorized as deleteIsUserAuthorizedFromSessionStorage,
} from "../functions/sessionStorageFunction";
import LOGIN_STATES from "../constants/LoginStates";
import LoadingScreen from "../components/LoadingScreen";
import useRefreshToken from "../hooks/auth/useRefreshToken";
import { scheduleTokenRefresh } from "../functions/tokenFunctions";
import { useAlert } from "react-alert";
import {
  AUTO_LOGIN_MAX_NO_OF_ATTEMPTS,
  AUTO_LOGIN_TIME_BETWEEN_ATTEMPTS_IN_SECONDS,
  AUTO_SUBSCRIPTION_UNSUCCESSFUL_MESSAGE,
  BUFFER_TO_REFRESH_TOKEN_IN_SECONDS,
} from "../constants/EnvConstants";
import Popup from "reactjs-popup";

const AppState = (props) => {
  const refreshTokenHookFunction = useRefreshToken();
  const alert = useAlert();
  const reloadWindow = () => window.location.reload();

  // TOKEN
  const [tokenObj, setTokenObj] = useState(getTokenFromSessionStorage());
  const saveToken = (tokenObj) => {
    setTokenObj(tokenObj);
    saveTokenInSessionStorege(tokenObj);
  };
  const deleteToken = () => {
    setTokenObj(undefined);
    deleteTokenFromSessionStorage();
  };

  // MSISDN
  const [msisdn, setMsisdn] = useState(getMsisdnFromSessionStorage());
  const saveMsisdn = (msisdn) => {
    setMsisdn(msisdn);
    saveMsisdnInSessionStorege(msisdn);
  };
  const deleteMsisdn = () => {
    setTokenObj(undefined);
    deleteMsisdnFromSessionStorage();
  };

  // User Name
  const [userName, setUserName] = useState(getUserNameFromSessionStorage());
  const saveUserName = (userName) => {
    setUserName(userName);
    saveUserNameInSessionStorege(userName);
  };
  const deleteUserName = () => {
    setTokenObj(undefined);
    deleteUserNameFromSessionStorage();
  };

  // Is User Authorized
  const [isUserAuthorized, setIsUserAuthorized] = useState(
    getIsUserAuthorizedFromSessionStorage()
  );
  const saveUserAuthorized = (isUserAuthorized) => {
    setIsUserAuthorized(isUserAuthorized);
    saveIsUserAuthorizedInSessionStorage(isUserAuthorized);
  };
  const deleteIsUserAuthorized = () => {
    setTokenObj(undefined);
    deleteIsUserAuthorizedFromSessionStorage();
  };

  const logout = () => {
    sessionStorage.clear();
    reloadWindow();
  };

  // User Login State
  const getCurrentLoginState = () => {
    return tokenObj && isUserAuthorized
      ? LOGIN_STATES.LOGGED_IN
      : tokenObj
      ? LOGIN_STATES.AUTO_LOGIN
      : LOGIN_STATES.NOT_LOGGED_IN;
  };
  const [loginState, setLoginState] = useState(getCurrentLoginState());
  const [isLoggedIn, setIsLoggedIn] = useState(
    loginState === LOGIN_STATES.LOGGED_IN && userName
  );
  useEffect(() => {
    const refreshLoginStateLoginState = () => {
      setLoginState(getCurrentLoginState());
    };

    refreshLoginStateLoginState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenObj, isUserAuthorized]);

  useEffect(() => {
    if (isLoggedIn && loginState === LOGIN_STATES.AUTO_LOGIN) {
      logout();
    } else {
      setIsLoggedIn(loginState === LOGIN_STATES.LOGGED_IN && userName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState, userName]);

  // TOKEN REFRESH FUNCTIONS <-- START -->
  const refreshTokenCallback = (data) => {
    const tokenObj = data?.tokenObj;
    saveToken(tokenObj);
    saveUserAuthorized(tokenObj?.tokenSubscriptionVerified || false);
  };

  useEffect(() => {
    const refreshToken = () => {
      //console.log("Refreshing Token");
      refreshTokenHookFunction(tokenObj.token, refreshTokenCallback);
    };

    if (tokenObj && tokenObj?.tokenSubscriptionVerified) {
      scheduleTokenRefresh(
        tokenObj.expiresAt,
        refreshToken,
        BUFFER_TO_REFRESH_TOKEN_IN_SECONDS
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenObj]);

  // TOKEN REFRESH FUNCTIONS <-- END -->

  // TOKEN REFRESH UNTIL SUBSCRIPTION FUNCTIONS <-- START -->

  const autoRefreshNoOfAttempts = useRef(0);
  const setAutoRefreshNoOfAttempts = (val) => {
    autoRefreshNoOfAttempts.current = val;
  };

  useEffect(() => {
    if (loginState === LOGIN_STATES.AUTO_LOGIN) {
      /*console.log(
        `{{ Auto Login }} TOKEN WILL REFRESH IN ${AUTO_LOGIN_TIME_BETWEEN_ATTEMPTS_IN_SECONDS} SECONDS.`
      );*/
      setTimeout(() => {
        //console.log("Refreshing Token Auto Login");
        refreshTokenHookFunction(tokenObj.token, (data) => {
          setAutoRefreshNoOfAttempts(autoRefreshNoOfAttempts.current + 1);
          if (
            autoRefreshNoOfAttempts.current >= AUTO_LOGIN_MAX_NO_OF_ATTEMPTS
          ) {
            deleteToken();
            deleteMsisdn();
            deleteUserName();
            deleteIsUserAuthorized();
            setLoginState(LOGIN_STATES.NOT_LOGGED_IN);
            reloadWindow();
            setTimeout(
              () => alert.error(AUTO_SUBSCRIPTION_UNSUCCESSFUL_MESSAGE),
              3000
            );
            return;
          }
          refreshTokenCallback(data);
        });
      }, AUTO_LOGIN_TIME_BETWEEN_ATTEMPTS_IN_SECONDS * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenObj, loginState]);

  // TOKEN REFRESH UNTIL SUBSCRIPTION FUNCTIONS <-- END -->

  // POPUP <<-- START -->>

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupChildren, setPopupChildren] = useState(<></>);
  const showPopup = (popupChildren) => {
    setPopupChildren(popupChildren);
    setIsPopupOpen(true);
  };
  const closePopup = () => {
    setPopupChildren(<></>);
    setIsPopupOpen(false);
  };
  // POPUP <<-- END -->>

  return (
    <AppContext.Provider
      value={{
        token: tokenObj?.token,
        saveToken,
        msisdn,
        saveMsisdn,
        userName,
        saveUserName,
        isUserAuthorized,
        saveUserAuthorized,
        isLoggedIn,
        showPopup,
        closePopup,
        logout,
      }}
    >
      {loginState === LOGIN_STATES.AUTO_LOGIN && userName && <LoadingScreen />}
      <Popup open={isPopupOpen} closeOnDocumentClick onClose={closePopup}>
        <div className="h-svh  bg-[#2f2a429f] w-[100vw] flex justify-center items-center">
          {popupChildren}
        </div>
      </Popup>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
