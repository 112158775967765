import React from "react";
import CustomButton from "../../reusable/CustomButton";
import {
  NO_OF_QUESTIONS_PER_STATIC_QUIZ,
  STATIC_QUIZ_POP_UP_ITEM_1,
  STATIC_QUIZ_POP_UP_ITEM_2,
  STATIC_QUIZ_POP_UP_ITEM_3,
  STATIC_QUIZ_POP_UP_ITEM_4,
  STATIC_QUIZ_POP_UP_ITEM_5,
  STATIC_QUIZ_POP_UP_KEY_NO_OF_QUESTIONS,
  STATIC_QUIZ_POP_UP_KEY_NO_OF_WINNERS,
  STATIC_QUIZ_POP_UP_KEY_REWARD_AMOUNT,
  STATIC_QUIZ_POP_UP_KEY_TIME_DURATION_OF_A_SEASON,
  STATIC_QUIZ_POP_UP_KEY_TIME_PER_QUESTION,
  STATIC_QUIZ_POP_UP_NO_OF_WINNERS,
  STATIC_QUIZ_POP_UP_REWARD_AMOUNT,
  STATIC_QUIZ_POP_UP_TIME_DURATION_OF_A_SEASON,
  STATIC_QUIZ_POP_UP_TITLE,
  STATIC_QUIZ_TIME_PER_QUESTION_IN_SECONDS,
} from "../../constants/EnvConstants";

const StartQuizPopUp = ({ goToNextStep }) => {
  const popUpReplaceValues = [
    {
      key: STATIC_QUIZ_POP_UP_KEY_NO_OF_QUESTIONS,
      value: NO_OF_QUESTIONS_PER_STATIC_QUIZ,
    },
    {
      key: STATIC_QUIZ_POP_UP_KEY_TIME_PER_QUESTION,
      value: STATIC_QUIZ_TIME_PER_QUESTION_IN_SECONDS,
    },
    {
      key: STATIC_QUIZ_POP_UP_KEY_NO_OF_WINNERS,
      value: STATIC_QUIZ_POP_UP_NO_OF_WINNERS,
    },
    {
      key: STATIC_QUIZ_POP_UP_KEY_TIME_DURATION_OF_A_SEASON,
      value: STATIC_QUIZ_POP_UP_TIME_DURATION_OF_A_SEASON,
    },
    {
      key: STATIC_QUIZ_POP_UP_KEY_REWARD_AMOUNT,
      value: STATIC_QUIZ_POP_UP_REWARD_AMOUNT,
    },
  ];

  const formattedText = (text) => {
    if (!text) {
      return "";
    }
    popUpReplaceValues.forEach((item) => {
      if (text.includes(item.key)) {
        text = text.replace(item.key, item.value);
      }
    });
    return text;
  };
  return (
    <section className="h-full grid place-items-center">
      <div className="bg-white box-shadow flex flex-col items-center gap-[3em] text-center px-[3em] tablet:px-[6em] pt-[4em] pb-[2em] rounded-xl  max-w-[90vw] mobile:min-w-[30em]">
        <div className="space-y-8">
          <p className="text-[#000640CC] text-[2.4em] font-[800]">
            {STATIC_QUIZ_POP_UP_TITLE}
          </p>
          <p className="text-[#45377ECC] text-[14px] text-left">
            <ul class="list-disc select-none">
              <li>{formattedText(STATIC_QUIZ_POP_UP_ITEM_1)}</li>
              <li>{formattedText(STATIC_QUIZ_POP_UP_ITEM_2)}</li>
              <li>{formattedText(STATIC_QUIZ_POP_UP_ITEM_3)}</li>
              <li>{formattedText(STATIC_QUIZ_POP_UP_ITEM_4)}</li>
              <li>{formattedText(STATIC_QUIZ_POP_UP_ITEM_5)}</li>
            </ul>
          </p>
        </div>
        <div>
          <CustomButton
            className={"w-[10em]"}
            onClick={goToNextStep}
            text={"OK"}
          />
        </div>
      </div>
    </section>
  );
};

export default StartQuizPopUp;
