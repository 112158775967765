import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { appContext } from "../context/context";
import { LoginPageRoute } from "../constants/RouteConstants";
const AuthRoute = () => {
  const { isLoggedIn } = useContext(appContext);
  const location = useLocation();
  return isLoggedIn ? <Outlet /> : <Navigate to={`${LoginPageRoute}${location.search}`} replace />;
};

export default AuthRoute;
