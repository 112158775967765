import React, { useContext, useEffect, useRef, useState } from "react";
import WaitingForPlayersToJoin from "../components/liveQuiz/WaitingForPlayersToJoin";
import TryingToJoin from "../components/liveQuiz/TryingToJoin";
import WaitingForNextQuestion from "../components/liveQuiz/WaitingForNextQuestion";
import Questions from "../components/liveQuiz/Questions";
import Result from "../components/liveQuiz/Result";
import { createChannel, createClient } from "agora-rtm-react";
import {
  AGORA_RTM_APP_ID,
  AGORA_RTM_CHANNEL_NAME,
} from "../constants/EnvConstants";
import { appContext, liveQuizContext } from "../context/context";
import { useAlert } from "react-alert";
import useGetRtmCredentials from "../hooks/liveQuiz/useGetRtmCredentials";
import { useNavigate } from "react-router-dom";
import { DashboardRoute } from "../constants/RouteConstants";
import useStatsOnJoin from "../hooks/liveQuiz/useStatsOnJoin";
import analytics from "../firebase";
import { logEvent } from "firebase/analytics";

const LiveQuiz = () => {
  const navigate = useNavigate();
  const joinShowStats = useStatsOnJoin();
  const STEP_TRYING_TO_LOG_IN = 0;
  const STEP_WAITING_FOR_PLAYERS_TO_JOIN = 1;
  const STEP_SHOW_QUESTION = 2;
  const STEP_WAITING_FOR_NEXT_QUESTION = 3;
  const STEP_RESULT_SCREEN = 4;
  const STEPS = [
    {
      step: STEP_TRYING_TO_LOG_IN,
      component: TryingToJoin,
    },
    {
      step: STEP_WAITING_FOR_PLAYERS_TO_JOIN,
      component: WaitingForPlayersToJoin,
    },
    {
      step: STEP_SHOW_QUESTION,
      component: Questions,
    },
    {
      step: STEP_WAITING_FOR_NEXT_QUESTION,
      component: WaitingForNextQuestion,
    },
    {
      step: STEP_RESULT_SCREEN,
      component: Result,
    },
  ];
  const [currentStep, setCurrentStep] = useState(STEP_TRYING_TO_LOG_IN);
  const [timerId, setTimerId] = useState(null);

  // Effect to set up the timer when stateVariable changes
  useEffect(() => {
    // Clear any existing timer
    if (timerId) {
      clearTimeout(timerId);
    }
    let newTimerId = null;
    if (currentStep === STEP_WAITING_FOR_NEXT_QUESTION) {
      // Set up a new timer
      newTimerId = setTimeout(() => {
        alert.error("Disconnected");
        navigate(DashboardRoute);
      }, 60000); // 60000 ms = 1 minute

      // Save the timer ID so it can be cleared if the stateVariable changes
      setTimerId(newTimerId);
    }
    // Clean up the timer on component unmount or if stateVariable changes
    return () => {
      if (newTimerId) {
        clearTimeout(newTimerId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === STEP_RESULT_SCREEN) {
      logEvent(analytics, "Live_Quiz_Resut_Screen_Opened", {
        date: new Date().toISOString(),
      });
    }
  }, [currentStep]);

  const goToSTEP_WAITING_FOR_PLAYERS_TO_JOIN = () =>
    setCurrentStep(STEP_WAITING_FOR_PLAYERS_TO_JOIN);
  const goToSTEP_SHOW_QUESTION = () => setCurrentStep(STEP_SHOW_QUESTION);
  const goToSTEP_WAITING_FOR_NEXT_QUESTION = () =>
    setCurrentStep(STEP_WAITING_FOR_NEXT_QUESTION);
  const goToSTEP_RESULT_SCREEN = () => setCurrentStep(STEP_RESULT_SCREEN);

  const { msisdn } = useContext(appContext);
  const { isLiveQuizQASessionStarted, setTotalQuestionsInQuiz } =
    useContext(liveQuizContext);
  const alert = useAlert();
  const getRtmCredentials = useGetRtmCredentials();
  // AGORA RTM FUNCTIONALITY <<-- START -->>
  const useClient = createClient(AGORA_RTM_APP_ID);
  const useChannel = createChannel(AGORA_RTM_CHANNEL_NAME);
  const client = useClient();
  const channel = useChannel(client);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [rtmToken, setRtmToken] = useState(null);
  const [isLoginInToRtm, setIsLoginInToRtm] = useState(false);
  const MAX_RTM_LOGIN_ATTEMPTS = 3;
  const rtmLoginAttemptsRef = useRef(0);
  const rtmLoginAttempts = rtmLoginAttemptsRef.current;
  const setRtmLoginAttempts = (val) => {
    rtmLoginAttemptsRef.current = val;
  };
  const MAX_RTM_CHANNEL_JOIN_ATTEMPTS = 3;
  const rtmChannelJoinAttemptsRef = useRef(0);
  const rtmChannelJoinAttempts = rtmChannelJoinAttemptsRef.current;
  const setRtmChannelJoinAttempts = (val) => {
    rtmChannelJoinAttemptsRef.current = val;
  };

  const handleMessageReceive = (message) => {
    const receivedQuestion = JSON.parse(message);
    // console.log(receivedQuestion);
    setCurrentQuestion(receivedQuestion);
    setTotalQuestionsInQuiz(receivedQuestion?.totalQuestions);
    goToSTEP_SHOW_QUESTION();
  };

  const handleRtmJoin = () => {
    goToSTEP_WAITING_FOR_PLAYERS_TO_JOIN();
    setIsLoginInToRtm(true);
    alert.success("Joined");
  };

  const handleLoginJoinAttemptsExhausted = () => {
    alert.error("Failed To Connect");
    navigate(DashboardRoute);
  };

  useEffect(() => {
    getRtmCredentials(setRtmToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginRtm = () => {
    setRtmLoginAttempts(rtmLoginAttempts + 1);
    // Log in to RTM system
    client
      .login({ token: rtmToken, uid: msisdn })
      .then(() => {
        //console.log("Listener logged in");

        const joinChannel = () => {
          setRtmChannelJoinAttempts(rtmChannelJoinAttempts + 1);
          channel
            .join()
            .then(() => {
              //    console.log(`Joined channel ${AGORA_RTM_CHANNEL_NAME}`);
              joinShowStats(handleRtmJoin, () =>
                setTimeout(
                  () =>
                    joinShowStats(handleRtmJoin, () =>
                      setTimeout(
                        () =>
                          joinShowStats(
                            handleRtmJoin,
                            handleLoginJoinAttemptsExhausted
                          ),
                        3000
                      )
                    ),
                  3000
                )
              );
              // Handle incoming channel messages
              channel.on("ChannelMessage", ({ text }) => {
                handleMessageReceive(text);
              });
            })
            .catch((err) => {
              console.error(
                `Failed to join channel ${AGORA_RTM_CHANNEL_NAME}`,
                err
              );
              if (rtmChannelJoinAttempts <= MAX_RTM_CHANNEL_JOIN_ATTEMPTS) {
                joinChannel();
              } else {
                handleLoginJoinAttemptsExhausted();
              }
            });
        };

        // Join RTM channel
        joinChannel();
      })
      .catch((err) => {
        console.error("Listener login failed", err);
        if (rtmLoginAttempts <= MAX_RTM_LOGIN_ATTEMPTS) {
          loginRtm();
        } else {
          handleLoginJoinAttemptsExhausted();
        }
      });
  };

  useEffect(() => {
    if (!rtmToken) {
      return;
    }

    // Set listener for receiving messages
    client.on("MessageFromPeer", handleMessageReceive);

    loginRtm();
    // Clean up when component unmounts
    return () => {
      if (client) {
        client.logout();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtmToken]);

  useEffect(() => {
    if (
      isLiveQuizQASessionStarted &&
      isLoginInToRtm &&
      currentStep === STEP_WAITING_FOR_PLAYERS_TO_JOIN
    ) {
      goToSTEP_WAITING_FOR_NEXT_QUESTION();
    }
  }, [currentStep, isLiveQuizQASessionStarted, isLoginInToRtm]);

  // AGORA RTM FUNCTIONALITY <<-- END -->>

  const SelectedComponent = STEPS.find(
    (step) => step.step === currentStep
  )?.component;

  return (
    <section className="h-svh">
      <SelectedComponent
        question={currentQuestion}
        goToSTEP_WAITING_FOR_PLAYERS_TO_JOIN={
          goToSTEP_WAITING_FOR_PLAYERS_TO_JOIN
        }
        goToSTEP_SHOW_QUESTION={goToSTEP_SHOW_QUESTION}
        goToSTEP_WAITING_FOR_NEXT_QUESTION={goToSTEP_WAITING_FOR_NEXT_QUESTION}
        goToSTEP_RESULT_SCREEN={goToSTEP_RESULT_SCREEN}
      />
    </section>
  );
};

export default LiveQuiz;
