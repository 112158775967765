import React, { useEffect } from "react";
import HelpAndFAQ from "../components/HelpAndFAQ/HelpAndFAQ";
import { logScreenView } from "../firebase";
function HelpFAQ() {
  useEffect(() => {
    logScreenView("Help & FAQ");
  }, []);
  return (
    <section className="tablet:p-[4em] mobile:p-[2em] p-[1em]">
      <HelpAndFAQ />
    </section>
  );
}

export default HelpFAQ;
