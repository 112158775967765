import { useContext, useState } from "react";
import { appContext, staticQuizContext } from "../../context/context";
import useHandleError from "../useHandleError";
import axios from "axios";
import {
  BASE_URL,
  ENDPOINT_GET_STATIC_QUIZ,
} from "../../constants/APIConstant";
import { NO_OF_QUESTIONS_PER_STATIC_QUIZ } from "../../constants/EnvConstants";

const useGetStaticQuiz = () => {
  const [loading, setLoading] = useState(false);

  const handleError = useHandleError();
  const { token } = useContext(appContext);
  const {
    startStaticQuizMode,
    setQuizKey,
    setStaticQuizQuestions,
    selectedCategories,
    quizDifficultyLevel,
  } = useContext(staticQuizContext);
  const getStaticQuiz = () => {
    setLoading(true);
    axios
      .request({
        method: "POST",
        url: BASE_URL + ENDPOINT_GET_STATIC_QUIZ,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify({
          categories: selectedCategories,
          difficultyLevel: quizDifficultyLevel,
          noOfQuestions: NO_OF_QUESTIONS_PER_STATIC_QUIZ,
        }),
      })
      .then((response) => {
        const data = response?.data;
        setQuizKey(data?.quizKey);
        setStaticQuizQuestions(data?.questions);
        startStaticQuizMode();
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => setLoading(false));
  };
  return { getStaticQuiz, loading };
};

export default useGetStaticQuiz;
