import React, { useContext, useEffect, useState } from "react";
import { OTP_RE_REQUEST_TIME_IN_SECONDS } from "../../constants/EnvConstants";
import useRequestOtp from "../../hooks/auth/useRequestOtp";
import { appContext, deviceContext } from "../../context/context";

const ReRequestOtp = () => {
  const { msisdn } = useContext(appContext);
  const { isMobileView } = useContext(deviceContext);
  const { requestOtp } = useRequestOtp(); // Custom hook to request OTP
  const [timeLeft, setTimeLeft] = useState(OTP_RE_REQUEST_TIME_IN_SECONDS);
  // Function to handle resend OTP
  const onResend = () => {
    requestOtp(msisdn, () => {}); // Request OTP with the msisdn
    setTimeLeft(OTP_RE_REQUEST_TIME_IN_SECONDS); // Reset the timer
  };
  // useEffect to handle the timer countdown
  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1); // Decrement the time left every second
      }, 1000);

      return () => clearInterval(timerId); // Clear the interval on component unmount
    }
  }, [timeLeft]);

  // Format the time left as mm:ss
  const timeLeftFormatted = `0:${timeLeft >= 10 ? timeLeft : `0${timeLeft}`}`;
  // Check if the resend button should be enabled
  const isResendEnabled = timeLeft === 0;
  // Conditional rendering based on device view type
  return isMobileView ? (
    <>
      <h1 className="text-[#FFFFFF99] text-[1.5em] font-[400] text-center mt-[1.2em]">
        {timeLeftFormatted}
      </h1>
      <div
        onClick={onResend}
        className={`${
          isResendEnabled ? "cursor-pointer" : "pointer-events-none opacity-30"
        } text-[#FFFFFFB3] text-[1.5em] font-[500] text-center mt-[2em] `}
      >
        Resend Code
      </div>
    </>
  ) : (
    <>
      <h1 className="text-[#45377ECC] text-[1.8em] font-[500] text-center mt-[2em]">
        {timeLeftFormatted}
      </h1>
      <div
        onClick={onResend}
        className={`${
          isResendEnabled ? "cursor-pointer" : "pointer-events-none opacity-30"
        } cursor-pointer text-[#45377ECC] text-[1.8em] font-[600] text-center mt-[4em]`}
      >
        Resend Code
      </div>
    </>
  );
};

export default ReRequestOtp;
