import React, { useContext, useState } from "react";
import { deviceContext } from "../../context/context";
import CustomButton from "../../reusable/CustomButton";
import NameInput from "../../reusable/NameInput";
import useUpdateUserName from "../../hooks/user/useUpdateUserName";

function UserName() {
  const { updateUserName, loading } = useUpdateUserName();
  const [name, setName] = useState("");
  const { isMobileView } = useContext(deviceContext);
  const handleClick = (event) => {
    event.preventDefault();
    if (name.length > 0) {
      updateUserName(name);
    }
  };
  return (
    <>
      {!isMobileView ? (
        <section className="whiteBox bg-white tablet:px-[8.7em] tablet:py-[5.8em] mobile:px-[5em]  mobile:py-[3.4em]   tablet:w-[69.6em] h-[60vh] rounded-[13px]">
          <h1 className="laptop:text-[3.5em] tablet:text-[2.5em] text-[1.8em] font-[900] text-[#45377E] text-center mb-[1.5em]">
            Enter your Name
          </h1>
          <form onSubmit={handleClick}>
            <NameInput name={name} setName={setName} />
            <CustomButton
              text={loading ? "Loading..." : "Continue"}
              type={"submit"}
              width="100%"
              className={`mt-[2.6em] ${
                name.length > 0 ? "" : "pointer-events-none"
              } `}
              isDisabled={loading || name?.length < 1}
            />
          </form>
        </section>
      ) : (
        <section className=" px-[2.6em]  w-[100%] mt-44 tablet:max-w-[69.6em]  ">
          <h1 className="laptop:text-[3.5em] tablet:text-[2.5em] text-[1.7em] font-[500] text-[#FFFFFFB3] text-center mb-[1em]">
            Enter your Name
          </h1>
          <form onSubmit={handleClick}>
            <NameInput name={name} setName={setName} />
            <CustomButton
              text={loading ? "Loading..." : "Continue"}
              type={"submit"}
              width="100%"
              className={"mt-[3em] !text-[1.5em] py-[1em]"}
              isDisabled={loading || name?.length < 1}
            />
          </form>
        </section>
      )}
    </>
  );
}
export default UserName;
