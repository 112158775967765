import React, { useContext } from "react";
import backIcon from "../../assets/images/back-icon.png";
import { deviceContext } from "../../context/context";
import { DashboardRoute } from "../../constants/RouteConstants";
import { useNavigate } from "react-router-dom";
import CircularProgressBar from "../CircularProgressBar";

const LeaveQuizNavBar = ({ timer, percentage, title }) => {
  const navigate = useNavigate();
  const { isMobileView } = useContext(deviceContext);
  const navigateToDashboard = () => navigate(DashboardRoute);
  const handleClick = () => navigateToDashboard();
  return (
    <div className="flex justify-between px-8 py-[2em] w-full border-b-[1px] border-b-[#4D3E86]">
      <button onClick={handleClick} className="flex items-center space-x-2">
        <img className="w-8 h-8 object-contain" src={backIcon} alt="back" />
        {isMobileView ? (
          <></>
        ) : (
          <span className="mobile:text-[2em] text-[1.8em]">
            {title || "Leave"}
          </span>
        )}
      </button>
      {timer && (
        <CircularProgressBar
          progressColor={"#A4A7D1"}
          borderBgColor={"#564591"}
          bgColor={"#45377E"}
          progress={percentage || timer}
          innerText={String(timer).padStart(2, "0")}
        />
      )}
    </div>
  );
};

export default LeaveQuizNavBar;
