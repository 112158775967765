import React, { useEffect, useState } from "react";
import useGetUserTotalScore from "../../hooks/userScore/useGetUserTotalScore";
import trophyIcon from "../../assets/svgs/trophyIcon.svg";
import { useNavigate } from "react-router-dom";
import { LeaderBoardRoute } from "../../constants/RouteConstants";

const PointsSection = ({ widthClass }) => {
  const navigate = useNavigate();
  const navigateToLeaderBoard = () => navigate(LeaderBoardRoute);
  const totalScoreKey = "totalScore";
  const getPoints = useGetUserTotalScore();
  const [points, setPoints] = useState(
    sessionStorage.getItem(totalScoreKey) || 0
  );

  const savePoints = (points) => {
    setPoints(points);
    sessionStorage.setItem(totalScoreKey, points);
  };

  useEffect(() => {
    getPoints(savePoints);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`flex items-center cursor-pointer ${
        widthClass || ""
      } text-[1.5em] tablet:text-[2em] space-x-4 p-4`}
      style={{
        background: "linear-gradient(90deg, #E55A9A 0%, #BA6BEB 100%)",
      }}
      onClick={navigateToLeaderBoard}
    >
      <div className="overflow-hidden">
        <img className="size-[30px]" src={trophyIcon} alt="points" />
      </div>
      <span className="">
        You’ve earned <b>{points}</b> points
      </span>
    </div>
  );
};

export default PointsSection;
