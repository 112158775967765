import React, { useState, useEffect } from "react";
import LeaveQuizNavBar from "./LeaveQuizNavBar";
import {
  LIVE_QUIZ_TIME_PER_QUESTION_IN_SECONDS,
  LIVE_QUIZ_TIME_TO_DISPLAY_ANSWER_BEFORE_NEXT_QUESTION_IN_SECONDS,
} from "../../constants/EnvConstants";
import useTestLiveQuizAnswer from "../../hooks/liveQuiz/useTestLiveQuizAnswer";
import LoadingScreen from "./LoadingScreen";

const QuestionScreen = ({
  goToSTEP_WAITING_FOR_NEXT_QUESTION,
  goToSTEP_RESULT_SCREEN,
  question,
}) => {
  const options = [
    question.option1,
    question.option2,
    question.option3,
    question.option4,
  ];
  const testLiveQuizAnswer = useTestLiveQuizAnswer();
  const [selectedOption, setSelectedOption] = useState(-1);
  const [isCorrect, setIsCorrect] = useState(false);
  const [timer, setTimer] = useState(LIVE_QUIZ_TIME_PER_QUESTION_IN_SECONDS);
  const [maxTime, setMaxTime] = useState(
    LIVE_QUIZ_TIME_PER_QUESTION_IN_SECONDS
  );
  const [showResult, setShowResult] = useState(false);
  const [questionStartTime, setQuestionStartTime] = useState(Date.now());
  const [answerSubmitTime, setAnswerSubmitTime] = useState(null);

  useEffect(() => {
    setQuestionStartTime(Date.now());
  }, []);

  useEffect(() => {
    if (timer > 0) {
      const timerId = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(timerId);
    } else if (timer === 0 && !showResult) {
      handleTimeEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  useEffect(() => {
    if (showResult) {
      setTimer(
        LIVE_QUIZ_TIME_TO_DISPLAY_ANSWER_BEFORE_NEXT_QUESTION_IN_SECONDS
      );
      setMaxTime(
        LIVE_QUIZ_TIME_TO_DISPLAY_ANSWER_BEFORE_NEXT_QUESTION_IN_SECONDS
      );
    }
  }, [showResult]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setAnswerSubmitTime(Date.now());
  };

  const handleTimeEnd = () => {
    const testAnswerCallback = (isTrue) => {
      setIsCorrect(isTrue);
      setShowResult(true);
      setTimeout(() => {
        if (question?.isLastQuestion) {
          goToSTEP_RESULT_SCREEN();
        } else {
          goToSTEP_WAITING_FOR_NEXT_QUESTION();
        }
      }, LIVE_QUIZ_TIME_TO_DISPLAY_ANSWER_BEFORE_NEXT_QUESTION_IN_SECONDS * 1000);
    };
    const timeTakenToAnswer = answerSubmitTime
      ? answerSubmitTime - questionStartTime
      : Date.now() - questionStartTime;

    testLiveQuizAnswer(
      question.questionId,
      selectedOption,
      timeTakenToAnswer,
      testAnswerCallback
    );
  };

  return (
    <div className="text-white h-svh bg-[#45377E] w-[100%] ">
      <LeaveQuizNavBar timer={timer} percentage={(timer * 100) / maxTime} />
      <div className="max-h-[calc(100vh_-_81px)] overflow-y-auto flex flex-col items-center">
        <div className="w-full mobile:w-[65%] min-w-3xl px-[4.7em] mobile:py-[5em] mobile:mt-[10vh] mt-[3em] mobile:border-[2px] mobile:border-[#5E4D96] rounded-[18px]">
          <span className="laptop:text-[2.4em] tablet:text-[2.2] mobile:text-[2.1em] text-[2em] font-[800] mb-2">
            Question {question?.questionNumber}
          </span>
          <span className="laptop:text-[1.8em] tablet:text-[1.7em] mobile:text-[1.6em] text-[1.4em]">
            /{question?.totalQuestions}
          </span>

          <p className="mb-6 laptop:text-[2.4em] tablet:text-[2.1em] mobile:text-[1.9em] text-[1.7em] font-[700] text-[#FFFFFFB3] select-none">
            {question.question}
          </p>
          <div className="grid mobile:grid-cols-2 gap-4 text-white laptop:text-[2em] tablet:text-[1.8em] mobile:text-[1.6em] text-[1.4em] mb-[1vh]">
            {options.map((option, i) => (
              <button
                key={i}
                className={`p-3 rounded-lg select-none ${
                  selectedOption === i + 1
                    ? showResult
                      ? isCorrect
                        ? "bg-[#83C3C4] font-[600]"
                        : "bg-[#FF7070] font-[600]"
                      : "border-[2px] border-[#FFFFF] text-[#FFFFFF] font-[600]"
                    : "border-[1px] border-[#5E4D96] text-[#A4A7D1]"
                }
              ${selectedOption !== -1 ? "pointer-events-none" : ""}`}
                onClick={() => handleOptionClick(i + 1)}
                disabled={showResult}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
        {selectedOption !== -1 && !showResult && (
          <div className="mt-12">
            <div>
              <LoadingScreen text={"Waiting for Other Players To Answer"} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionScreen;
