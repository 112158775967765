import React, { useContext, useEffect, useState } from "react";
import { liveQuizContext } from "../context/context";
import LIVE_QUIZ_STATUS_CONSTANTS from "../constants/LiveQuizStatusConstants";

const Countdown = ({ targetDate }) => {
  const { setScheduledQuiz } = useContext(liveQuizContext);
  const calculateTimeLeft = (difference) => {
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(+new Date(targetDate) - +new Date())
  );

  useEffect(() => {
    const timer = setInterval(() => {
      const now = Date.now();
      const difference = +new Date(targetDate) - now;
      setTimeLeft(calculateTimeLeft(difference));

      if (difference <= 0) {
        setScheduledQuiz((vals) => ({
          ...vals,
          status: LIVE_QUIZ_STATUS_CONSTANTS.LIVE,
        }));
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDate]);

  // eslint-disable-next-line
  const formatTime = () => {
    const { days, hours, minutes, seconds } = timeLeft;

    if (days > 0) {
      const formattedTime = [
        days?.toString()?.padStart(2, "0"),
        hours?.toString()?.padStart(2, "0"),
        minutes?.toString()?.padStart(2, "0"),
        seconds?.toString()?.padStart(2, "0"),
      ].join(":");

      return formattedTime;
    } else {
      const formattedTime = [
        hours?.toString()?.padStart(2, "0"),
        minutes?.toString()?.padStart(2, "0"),
        seconds?.toString()?.padStart(2, "0"),
      ].join(":");

      return formattedTime;
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center mt-4">
        <div className="flex items-baseline">
          {timeLeft?.days > 0 && (
            <>
              <div className="flex flex-col items-center mx-1">
                <span className="laptop:text-[4em] tablet:text-[3.5em] mobile:text-[3em] text-[3em] font-[800] text-[#000640]">
                  {timeLeft?.days?.toString()?.padStart(2, "0")}
                </span>
                <span className="laptop:text-[1.6em] tablet:text-[1.4em] mobile:text-[1.3em] text-[1.3em] text-[#00064066]">
                  DD
                </span>
              </div>
              <span className="laptop:text-[4em] tablet:text-[3.5em] mobile:text-[3em] text-[3em] font-[800] text-[#000640]">
                :
              </span>
            </>
          )}
          <div className="flex flex-col items-center mx-1">
            <div className="flex items-baseline">
              <span className="laptop:text-[4em] tablet:text-[3.5em] mobile:text-[3em] text-[3em] font-[800] text-[#000640]">
                {timeLeft?.hours?.toString()?.padStart(2, "0") || "00"}
              </span>
              <span className="laptop:text-[4em] tablet:text-[3.5em] mobile:text-[3em] text-[3em] font-[800] text-[#000640]">
                :
              </span>
            </div>
            <span className="laptop:text-[1.6em] tablet:text-[1.4em] mobile:text-[1.3em] text-[1.3em] text-[#00064066]">
              HH
            </span>
          </div>
          <div className="flex flex-col items-center mx-1">
            <div className="flex items-baseline">
              <span className="laptop:text-[4em] tablet:text-[3.5em] mobile:text-[3em] text-[3em] font-[800] text-[#000640]">
                {timeLeft?.minutes?.toString()?.padStart(2, "0") || "00"}
              </span>
              <span className="laptop:text-[4em] tablet:text-[3.5em] mobile:text-[3em] text-[3em] font-[800] text-[#000640]">
                :
              </span>
            </div>
            <span className="laptop:text-[1.6em] tablet:text-[1.4em] mobile:text-[1.3em] text-[1.3em] text-[#00064066]">
              MM
            </span>
          </div>
          <div className="flex flex-col items-center mx-1">
            <div className="flex items-baseline">
              <span className="laptop:text-[4em] tablet:text-[3.5em] mobile:text-[3em] text-[3em] font-[800] text-[#000640]">
                {timeLeft?.seconds?.toString()?.padStart(2, "0") || "00"}
              </span>
            </div>
            <span className="laptop:text-[1.6em] tablet:text-[1.4em] mobile:text-[1.3em] text-[1.3em] text-[#00064066]">
              SS
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
