import React, { useContext } from "react";
import { deviceContext } from "../../context/context";
import backIcon from "../../assets/images/back-icon.png";

const BackHeader = ({ onClick, isabsolute }) => {
  const { isTabletView } = useContext(deviceContext);
  return isTabletView ? (
    <div
      className={`${
        isabsolute ? "absolute top-0 left-0 z-[999]" : ""
      } p-10`}
      onClick={onClick}
    >
      <div className="w-10">
        <img className="w-full h-full object-contain" src={backIcon} alt="" />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default BackHeader;
