const PrivacyPolicyData = {
  title: "QuizWiz Privacy Policy",
  sections: [
    {
      title: "Introduction",
      content:
        "Welcome to QuizWiz. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you use our service.",
    },
    {
      title: "Information We Collect",
      contentHeadline:
        "We collect various types of information to provide and improve our services:",
      content: [
        "Personal Information: When you register for an account, we may collect personal information such as your name, email address, phone number, and other contact details.",
        "Usage Data: We collect information on how you use QuizWiz, including your quiz participation, scores, and activity on our platform.",
        "Device Information: We collect information about the device you use to access QuizWiz, such as IP address, browser type, and operating system.",
      ],
    },
    {
      title: "How We Use Your Information",
      contentHeadline:
        "We use the information we collect for various purposes, including:",
      content: [
        "Providing and Improving Services: To operate QuizWiz, personalize your experience, and improve our services.",
        "Communication: To send you updates, promotional materials, and other information related to QuizWiz. You can opt out of receiving these communications at any time.",
        "Analytics: To analyze usage patterns and improve our platform's functionality and user experience.",
        "Compliance: To comply with legal obligations and protect our rights and the rights of our users.",
      ],
    },
    {
      title: "Sharing Your Information",
      contentHeadline:
        "We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following circumstances:",
      content: [
        "Service Providers: We may share your information with third-party service providers who assist us in operating QuizWiz and providing our services.",
        "Legal Requirements: We may disclose your information when required by law or in response to valid legal processes.",
        "Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owner.",
      ],
    },
    {
      title: "Data Security",
      content:
        "We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.",
    },
    {
      title: "Your Rights",
      contentHeadline:
        "You have certain rights regarding your personal information:",
      content: [
        "Access and Correction: You can access and update your personal information through your account settings.",
        "Deletion: You can request the deletion of your personal information from within the webApp.",
        "Opt-Out: You can opt-out of receiving promotional communications from us by following the unsubscribe instructions in those communications.",
      ],
    },
    {
      title: "Cookies and Tracking Technologies",
      content:
        "We use cookies and similar tracking technologies to enhance your experience on QuizWiz. Cookies are small files that are stored on your device that help us understand how you use our site and improve your experience. You can manage your cookie preferences through your browser settings.",
    },
    {
      title: "Third-Party Links",
      content:
        "QuizWiz may contain links to third-party websites. We are not responsible for the privacy practices of these websites, and we encourage you to review their privacy policies.",
    },
    {
      title: "Changes to This Privacy Policy",
      content:
        "We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you of significant changes through email or other means.",
    },
  ],
  finalNotice:
    "By using QuizWiz, you acknowledge that you have read, understood, and agree to the practices described in this Privacy Policy.",
};

export default PrivacyPolicyData;
