import React, { useContext, useState } from "react";
import CustomButton from "../../reusable/CustomButton";
import tickIcon from "../../assets/images/icons/tickIcon.png";
import backIcon from "../../assets/images/back-icon.png";
import StaticQuizDifficultyLevels from "../../constants/StaticQuizDifficultyLevels";
import { staticQuizContext } from "../../context/context";

const Levels = ({ goToNextStep, goToPreviousStep }) => {
  const [selectedLevel, setSelectedLevel] = useState(0);

  const { setQuizDifficultyLevel } = useContext(staticQuizContext);

  const handleRadioChange = (level) => {
    setSelectedLevel(level);
  };

  const handleSubmit = () => {
    setQuizDifficultyLevel(selectedLevel);
    goToNextStep();
  };

  return (
    <div className="flex flex-col items-center text-white mobile:my-[5em] my-[2em] ">
      <h1 className="laptop:text-[4.2em] tablet:text-[4em] mobile:text-[3.5em] text-[3em] font-[600] mb-2 text-center">
        Levels
      </h1>
      <p className="laptop:text-[2em] tablet:text-[1.9em] mobile:text-[1.8em] text-[1.5em] mb-6 text-center">
        Select the difficulty level of your choice
      </p>
      <div className="grid gap-4 mb-6 px-8 w-[100%] tablet:w-[60%] ">
        {StaticQuizDifficultyLevels.map((level) => {
          const isChecked = selectedLevel === level.val;
          return (
            <div
              key={level.val}
              className="bg-[#5E4D9659] !p-[0em] rounded-[13px] flex items-center justify-between cursor-pointer "
            >
              <div
                className="flex items-center w-full justify-between p-[3em] cursor-pointer bg-slate-400-"
                onClick={() => handleRadioChange(level.val)}
              >
                <span className="laptop:text-[2.4em] tablet:text-[2em] mobile:text-[1.8em] text-[1.6em] text-[#CCC8DB] font-[700]">
                  {level.name}
                </span>
                <div
                  className={`size-[20px] rounded-full ${
                    isChecked ? "" : "border-[#FFF] border-[1px]"
                  }`}
                >
                  {isChecked ? (
                    <div className="bg-[#83C3C4] w-full h-full rounded-full flex items-center justify-center">
                      <img className="w-[10px]" src={tickIcon} alt="" />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="mobile:mt-48 mt-14 flex flex-col ">
        <CustomButton
          text="Continue"
          onClick={handleSubmit}
          isDisabled={selectedLevel === 0}
          className={
            "tablet:!text-[2em] mobile:!text-[1.8em] !text-[1.5em] font-[600] mobile:!py-[1em] !w-[19em] px-[1.5em] !py-[1.2em] uppercase"
          }
        />
        <div
          onClick={goToPreviousStep}
          className=" cursor-pointer hidden mobile:flex space-x-3 mobile:mt-[5.3em] mt-[2em] justify-center items-center"
        >
          <img className="object-contain" src={backIcon} alt="" />
          <button className="text-white text-[1.8em]">GO BACK</button>
        </div>
      </div>
    </div>
  );
};

export default Levels;
