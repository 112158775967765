export const getCurrentTimeAsLocalDateTime = () => {
  const currentDate = new Date();
  const currentTimeArray = [
    currentDate.getFullYear(),
    currentDate.getMonth() + 1, // Adding 1 because getMonth() returns zero-based month
    currentDate.getDate(),
    currentDate.getHours(),
    currentDate.getMinutes(),
    currentDate.getSeconds(),
    currentDate.getMilliseconds(),
  ];
  //console.log(expirationTime);
  return currentTimeArray;
};
export const convertLocalDateTimeToTime = (localDateTimeArray) => {
  try {
    const [year, month, day, hour, minute, second] = localDateTimeArray;
    return new Date(year, month - 1, day, hour, minute, second).getTime();
  } catch (e) {
    console.error("Error", e);
    return null;
  }
};
export const getCurrentTime = () => {
  return new Date().getTime();
};
export const checkIfFirstTimeIsBeforeSecondTime = (time1, time2) => {
  // Create Date objects from the time arrays
  const date1 = new Date(
    time1[0],
    time1[1] - 1,
    time1[2],
    time1[3],
    time1[4],
    time1[5],
    time1[6]
  ).getTime();
  const date2 = new Date(
    time2[0],
    time2[1] - 1,
    time2[2],
    time2[3],
    time2[4],
    time2[5],
    time2[6]
  ).getTime();

  // Compare the Date objects
  if (date1 < date2) {
    return true;
  } else if (date1 > date2) {
    return false;
  } else {
    return true;
  }
};

export function getCurrentTimestamp() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export function hasCurrentTimePassed(timestamp) {
  // Convert the given timestamp to a Date object
  const givenDate = new Date(timestamp);

  // Get the current date and time
  const currentDate = new Date();

  // Compare the current date and the given date
  return currentDate > givenDate;
}

export function calculateTimestampDifferenceInSeconds(timestamp1, timestamp2) {
  // Convert timestamps to Date objects
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  // Calculate the difference in milliseconds
  const differenceInMs = Math.abs(date2 - date1);

  // Convert milliseconds to seconds
  const differenceInSeconds = Math.floor(differenceInMs / 1000);

  return differenceInSeconds;
}
