import React, { useContext, useState } from "react";
import { handleChange as handleChangeFunction } from "../functions/formFunctions";
import { deviceContext } from "../context/context";

const MSISDNInput = ({ Msisdn, setMsisdn }) => {
  const { isMobileView } = useContext(deviceContext);
  const [isFocused, setIsFocused] = useState(false);
  const handleChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    if ((!value.includes("-")) || value.length === 0) {
      handleChangeFunction(event, Msisdn, setMsisdn);
    }
  };
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return !isMobileView ? (
    <div
      className={`flex items-center borderInput rounded-[14px] p-5 tablet:text-[1.8em] text-[1.4em] text-[#999BBE] ${
        isFocused ? "focusInput" : ""
      }`}
    >
      <span className="text-gray-400 mr-2- cursor-default">03</span>
      <input
        type="tel"
        className={`outline-none text-gray-400 w-full -border-l-[2px] -border-l-[#999bbe9d] -pl-2 `}
        placeholder="XXXXXXXXX"
        value={Msisdn}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        pattern="^[1-9][0-9]*$"
        maxLength={9}
      />
    </div>
  ) : (
    <div
      className={`flex items-center bg-[#5E4D96CC] rounded-[14px] p-5 text-[1.5em]  text-[#999BBE] ${
        isFocused ? "focusInput" : ""
      }`}
    >
      <span className="text-gray-400 mr-2- cursor-default">03</span>
      <input
        type="tel"
        className={`outline-none bg-transparent text-gray-400 w-full -border-l-[2px] -border-l-[#999bbe9d] -pl-2 `}
        placeholder="XXXXXXXXX"
        value={Msisdn}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        pattern="^[1-9][0-9]*$"
        maxLength={9}
      />
    </div>
  );
};

export default MSISDNInput;
