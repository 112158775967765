import axios from "axios";
import useHandleError from "../useHandleError";
import {
  BASE_URL,
  ENDPOINT_GET_STATIC_QUIZ_CATEGORIES,
} from "../../constants/APIConstant";
import { useContext, useState } from "react";
import { appContext } from "../../context/context";

const useGetCategories = () => {
  const handleError = useHandleError();
  const { token } = useContext(appContext);
  const [fetching, setFetching] = useState(false);

  const getCategories = (callback) => {
    setFetching(true);
    axios
      .request({
        method: "GET",
        url: BASE_URL + ENDPOINT_GET_STATIC_QUIZ_CATEGORIES,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        callback(response?.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => setFetching(false));
  };
  return { getCategories, fetching };
};

export default useGetCategories;
