export const BaseRoute = "/";
export const DashboardRoute = "/dashboard";
export const LoginPageRoute = "/auth";
export const AboutUsRoute = "/about-us";
export const HelpAndFAQRoute = "/help-and-faq";
export const SupportAndFeedbackRoute = "/support-and-feedback";
export const TermsAndConditionsRoute = "/terms-and-conditions";
export const TermsAndConditionsPageRoute = "/p/terms-and-conditions";
export const PoliciesPageRoute = "/p/privacy-policy";
export const PoliciesRoute = "/privacy-policy";
export const DailyQuizRoute = "/daily-quiz";
export const LeaderBoardRoute = "/leader-board";
export const LiveQuizRoute = "/live-quiz";
export const EmptyPageRoute = "/blank-page";
export const NoMatchRoute = "*";