import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../reusable/CustomButton";
// import CheckboxbgImage from "../../assets/images/categories-checkboxes-container.png";
import tickIcon from "../../assets/images/icons/tickIcon.png";
import backIcon from "../../assets/images/back-icon.png";
// import LoadingComponent from "../LoadingComponent";
import useGetCategories from "../../hooks/staticQuiz/useGetCategories";
import { DashboardRoute } from "../../constants/RouteConstants";
import { useNavigate } from "react-router-dom";
import { staticQuizContext } from "../../context/context";
import StaticQuizCategoryImage from "./StaticQuizCategoryImage";
import LoadingScreen from "../liveQuiz/LoadingScreen";

const Categories = ({ goToNextStep }) => {
  const navigate = useNavigate();
  const { getCategories, fetching } = useGetCategories();
  const staticQuizState = useContext(staticQuizContext);
  const [allCategories, setAllCategories] = useState(
    staticQuizState.selectedCategories
  );
  const [selectedCategories, setSelectedCategories] = useState([]);
  const navigateToDashboard = () => navigate(DashboardRoute);

  const handleCheckboxChange = (category) => {
    setSelectedCategories((prevSelectedCategories) =>
      prevSelectedCategories.includes(category)
        ? prevSelectedCategories.filter((c) => c !== category)
        : [...prevSelectedCategories, category]
    );
  };

  useEffect(() => {
    getCategories(setAllCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    goToNextStep();
    staticQuizState.setSelectedCategories(selectedCategories);
  };

  if (fetching) {
    return (
      <div className="grid place-items-center h-[70vh]">
        <LoadingScreen />
      </div>
    );
  }

  if (allCategories.length === 0) {
    return (
      <section className="grid place-items-center h-[70vh]">
        <div>
          <div className="text-[2em]">No Categories Found :(</div>
          <div
            onClick={navigateToDashboard}
            className="cursor-pointer hidden mobile:flex space-x-3 mobile:mt-[5.3em] mt-[2em]  justify-center items-center"
          >
            <img className=" object-contain" src={backIcon} alt="" />
            <button className="text-white text-[1.8em]">GO BACK</button>
          </div>
        </div>
      </section>
    );
  }

  return (
    <div className="flex flex-col items-center text-white   mobile:mt-[5em] max-h-svh   ">
      <h1 className="laptop:text-[4.2em] tablet:text-[4em] mobile:text-[3.5em] text-[3em]  font-[600] mb-2- text-center">
        Categories
      </h1>
      <p className="laptop:text-[2em] tablet:text-[1.9em] mobile:text-[1.8em] text-[1.5em] mb-6 text-center">
        Select 1 or multiple categories
      </p>
      <div className="grid mobile:grid-cols-2 gap-4 mb-6 py-5 px-8 w-[100%] mobile:w-auto max-h-[55vh] overflow-scroll overflowclass  box-shadow">
        {allCategories.map((category, index) => {
          const isChecked = selectedCategories.includes(category.id);
          return (
            <StaticQuizCategoryImage
              key={index}
              className={`bg-[#5E4D9659] box-shadow !p-[0em] rounded-[13px] h-36 mobile:min-w-[25em] tablet:min-w-[30em] laptop:min-w-[40em] flex items-center justify-between cursor-pointer  ${
                allCategories.length === 1 ? "col-span-2" : ""
              } `}
              imageKey={category?.key}
            >
              <div
                className="flex items-center w-full justify-between p-[3em] cursor-pointer bg-slate-400-"
                onClick={() => handleCheckboxChange(category.id)}
              >
                <span className="laptop:text-[2.4em] tablet:text-[2em] mobile:text-[1.8em] text-[1.6em] text-[#CCC8DB] font-[700]">
                  {category.name}
                </span>
                <div
                  className={`size-[20px] rounded-full ${
                    isChecked ? "" : "border-[#FFF] border-[1px]"
                  }`}
                >
                  {isChecked ? (
                    <div className="bg-[#83C3C4] w-full h-full rounded-full flex items-center justify-center">
                      <img className="w-[10px]" src={tickIcon} alt="" />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </StaticQuizCategoryImage>
          );
        })}
      </div>

      <div className="mobile:mt-24 mt-6 flex flex-col ">
        <CustomButton
          text="Continue"
          onClick={handleSubmit}
          isDisabled={selectedCategories.length === 0}
          className={
            "tablet:!text-[2em] mobile:!text-[1.8em] !text-[1.5em] font-[600] mobile:!py-[1em] !w-[19em] px-[1.5em] !py-[1.2em] uppercase"
          }
        />
        <div
          onClick={navigateToDashboard}
          className="cursor-pointer hidden mobile:flex space-x-3 mobile:mt-[5.3em] mt-[2em]  justify-center items-center"
        >
          <img className=" object-contain" src={backIcon} alt="" />
          <button className="text-white text-[1.8em]">GO BACK</button>
        </div>
      </div>
    </div>
  );
};

export default Categories;
