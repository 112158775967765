import React, { useContext } from "react";
import logoutIcon from "../../assets/images/icons/logoutIcon.png";
import { appContext } from "../../context/context";

const LogoutBtn = ({ isOpen }) => {
  const { showPopup, closePopup, logout } = useContext(appContext);
  const showConfirmationDiv = () => showPopup(confirmationDiv);
  const logoutFunction = () => {
    closePopup();
    logout();
  };
  const btnClass = "px-4 py-2 tablet:px-6 tablet:py-3 rounded-md";
  const confirmationDiv = (
    <div className="relative bg-white space-y-16 rounded-lg shadow-lg  transform transition-all min-w-[60vw] laptop:min-w-[30vw]  mx-10 text-center mobile:h-[40vh] h-[30vh] px-10 py-12  ">
      <div className="h-full w-full flex flex-col justify-between">
        <div className="mobile:text-[2.5em] text-[1.5em]">
          Are You Sure to Logout?
        </div>
        <div className="flex flex-col-reverse tablet:flex-row items-center justify-evenly gap-4">
          <button
            className={`${btnClass} text-[#31486F] mobile:text-[2em] text-[1.5em]`}
            onClick={closePopup}
          >
            Cancel
          </button>
          <button
            className={`${btnClass} text-white bg-[#31486F] mobile:text-[2em] text-[1.5em]`}
            onClick={logoutFunction}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
  return (
    <div className="w-full flex justify-center" onClick={showConfirmationDiv}>
      {isOpen ? (
        <button className="text-[1.3em] px-6 py-2  border-[1px] border-[#fff] rounded-full text-center flex justify-center ">
          LOGOUT
        </button>
      ) : (
        <img className="w-[4em] rounded-full " src={logoutIcon} alt="logout" />
      )}
    </div>
  );
};

export default LogoutBtn;
