// /data/AboutUsData.jsx
const AboutUsData = {
  title: "About QuizWiz",
  welcome:
    "Welcome to QuizWiz, your ultimate destination for interactive and engaging quizzes! QuizWiz is a cutting-edge web app designed to challenge your knowledge and offer exciting rewards for your participation. Whether you're a trivia enthusiast or just looking for a fun way to test your skills, QuizWiz has something for everyone.",
  missionTitle: "Our Mission",
  mission:
    "At QuizWiz, our mission is to provide a platform that combines entertainment and learning. We aim to create a vibrant community where users can enjoy daily quizzes and compete in live quiz battles, all while earning fantastic rewards. Our goal is to make learning fun and rewarding, fostering a spirit of healthy competition and intellectual growth.",
  featuresTitle: "Features",
  features: [
    "Daily Quizzes: Test your knowledge with our daily quizzes on a wide range of topics. Each quiz is carefully crafted to challenge and entertain you.",
    "Live Battle Quizzes: Join real-time quiz battles against other users and prove your trivia prowess. Winners get exciting prizes and bragging rights!",
    "Incentives and Rewards: Earn mobile data, gift cards, and other rewards as you participate and win in our quizzes. Our incentives are designed to make your QuizWiz experience even more rewarding.",
    "User-Friendly Interface: Enjoy a seamless and intuitive user experience with our easy-to-navigate web app. QuizWiz is accessible on various devices, ensuring you can play anytime, anywhere.",
  ],
  whyChooseUsTitle: "Why Choose QuizWiz?",
  whyChooseUs:
    "QuizWiz stands out for its blend of fun and educational value. We believe in making learning an enjoyable journey, and our quizzes are designed to be both informative and entertaining. With QuizWiz, you not only get to test your knowledge but also have the opportunity to win great prizes and enhance your learning experience.",
};

export default AboutUsData;
